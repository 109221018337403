import React, { useState, useEffect, useContext } from "react";
import { TextareaAutosize } from "@material-ui/core";
import { OrangeToolTip } from "../../userInterfaceComponents/CustomUIComponents";
import { PostDetailsContext } from "../../contexts/PostDetails";
import { getCategoryFields } from "../../helper/category.helper";
import { categoryDescFields } from "./../../constants";
import { toolStyle, toolStyleActive } from "../../assets/css/TextToolbar";

//Quill Implementation
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../../assets/css/quill.css";

const CategoryDescFields = ({ classes, display }) => {
  const { postInfo, setPostInfo } = useContext(PostDetailsContext);
  const [categoryFields, setCategoryFields] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [value, setValue] = useState("");
  const [name, setName] = useState("");
  const [textStyle, setStyle] = useState({
    title: {
      fontWeight: "regular",
      fontStyle: "normal",
    },
    meta: {
      fontWeight: "regular",
      fontStyle: "normal",
    },
    brief: {
      fontWeight: "regular",
      fontStyle: "normal",
    },
    howTo: {
      fontWeight: "regular",
      fontStyle: "normal",
    },
    service: {
      fontWeight: "regular",
      fontStyle: "normal",
    },
    knowFor: {
      fontWeight: "regular",
      fontStyle: "normal",
    },
    toDo: {
      fontWeight: "regular",
      fontStyle: "normal",
    },
    equipment: {
      fontWeight: "regular",
      fontStyle: "normal",
    },
    specialty: {
      fontWeight: "regular",
      fontStyle: "normal",
    },
    ingredients: {
      fontWeight: "regular",
      fontStyle: "normal",
    },
    preparation: {
      fontWeight: "regular",
      fontStyle: "normal",
    },
    collections: {
      fontWeight: "regular",
      fontStyle: "normal",
    },
    history: {
      fontWeight: "regular",
      fontStyle: "normal",
    },
    landscape: {
      fontWeight: "regular",
      fontStyle: "normal",
    },
    fauna: {
      fontWeight: "regular",
      fontStyle: "normal",
    },
    currentFunc: {
      fontWeight: "regular",
      fontStyle: "normal",
    },
    geology: {
      fontWeight: "regular",
      fontStyle: "normal",
    },
    interest: {
      fontWeight: "regular",
      fontStyle: "normal",
    },
    facilities: {
      fontWeight: "regular",
      fontStyle: "normal",
    },
    know: {
      fontWeight: "regular",
      fontStyle: "normal",
    },
    info: {
      fontWeight: "regular",
      fontStyle: "normal",
    },
  });
  const [toolbar, setToolbar] = useState({
    status: false,
    section: null,
  });

  let { description } = postInfo ? postInfo : [];
  description = description ? description : {};

  useEffect(() => {
    setCategoryFields(
      getCategoryFields(postInfo.category, postInfo.subCategory)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Options for rich text tool bar
  const toolbarOptions = ["bold", "italic", "link"];

  const handleChange = (evt) => {
    let { name, value } = evt.target;

    postInfo
      ? postInfo.description
        ? setPostInfo({
            ...postInfo,
            description: { ...postInfo.description, [name]: value },
          })
        : setPostInfo({ ...postInfo, description: { [name]: value } })
      : setPostInfo({ description: { [name]: value } });
  };

  const handleChangeEditor = (content, delta, source, editor) => {
    const fieldContent = editor.getContents();
    postInfo
      ? postInfo.description
        ? setPostInfo({
            ...postInfo,
            description: { ...postInfo.description, [name]: content },
          })
        : setPostInfo({ ...postInfo, description: { [name]: content } })
      : setPostInfo({ description: { [name]: fieldContent } });

    setValue(fieldContent);
  };

  return (
    <div>
      {/* 1. Title */}
      {categoryFields.includes(categoryDescFields.TITLE) && (
        <OrangeToolTip title="Title" arrow placement="right">
          <div className={classes.desc__box}>
            <h4 className={classes.descHeading}>Title</h4>
            <TextareaAutosize
              className={classes.desc__input}
              placeholder="Title"
              value={description?.title}
              name="title"
              onChange={handleChange}
              readOnly={display}
              onClick={() =>
                setToolbar({
                  status: true,
                  section: "title",
                })
              }
              style={{
                fontWeight: textStyle.title.fontWeight,
                fontStyle: textStyle.title.fontStyle,
              }}
            />
          </div>
        </OrangeToolTip>
      )}
      {/* 2.	Meta Description */}
      {categoryFields.includes(categoryDescFields.META_DESCRIPTION) && (
        <OrangeToolTip title="Meta Description" arrow placement="right">
          <div className={classes.desc__box}>
            <h4 className={classes.descHeading}>Meta Description</h4>

            {display === true ? (
              <ReactQuill
                className={classes.desc__input}
                modules={{ toolbar: false }}
                readOnly={true}
                placeholder="Meta Description"
                value={description?.metaDescription || ""}
                defaultValue={description?.metaDescription || ""}
              >
                <div className="my-editing-area" />
              </ReactQuill>
            ) : (
              <ReactQuill
                className={classes.desc__input}
                modules={{ toolbar: toolbarOptions }}
                onChange={handleChangeEditor}
                onFocus={(e) => setName("metaDescription")}
                defaultValue={description?.metaDescription || ""}
                theme={"snow"} // pass false to use minimal theme
              />
            )}
          </div>
        </OrangeToolTip>
      )}
      {/* 3.	Brief Description */}
      {categoryFields.includes(categoryDescFields.BRIEF_DESCRIPTION) && (
        <OrangeToolTip
          title={
            postInfo.subCategory === "Mountains and Regional Parks" ||
            postInfo.subCategory === "Volcanoes"
              ? "Introduction (type, geography)"
              : postInfo.subCategory === "Hotels"
              ? "Introduction (include the vibe, for which type of traveller can be recommended etc)"
              : postInfo.subCategory === "Recipes"
              ? "Brief description"
              : postInfo.subCategory === "Churches/Cathedrals"
              ? "Introduction (structure, architecture etc)"
              : postInfo.category === "Experiences"
              ? "Introduction, type of activities etc, if you think there are headers missing please include the information in this box"
              : postInfo.category === "Food and Drink"
              ? "Introduction ( in this box you can include several details and stories).INCLUDE: the Food itself (e.g. vegetarian, seafood,steak), type of cuisine(e.g.French, Italian etc), Style of offering (e.g.tapas bar, a sushi train, atastet restaurant, a buffet restaurant etc) and service(From inexpensive fastfood restaurants and cafeterias, to mid-priced family restaurants, tohigh-priced luxury establishments)"
              : "Introduction"
          }
          arrow
          placement="right"
        >
          <div className={classes.desc__box}>
            <h4 className={classes.descHeading}>Brief Description</h4>
            {display === true ? (
              <ReactQuill
                className={classes.desc__input}
                modules={{ toolbar: false }}
                readOnly={true}
                placeholder="Brief Description"
                value={description?.briefDescription || ""}
                defaultValue={description?.briefDescription || ""}
              >
                <div className="my-editing-area" />
              </ReactQuill>
            ) : (
              <ReactQuill
                className={classes.desc__input}
                modules={{ toolbar: toolbarOptions }}
                onChange={handleChangeEditor}
                onFocus={(e) => setName("briefDescription")}
                defaultValue={description?.metaDescription || ""}
                theme={"snow"} // pass false to use minimal theme
              />
            )}
          </div>
        </OrangeToolTip>
      )}
      {/* 4.	How to get there? */}
      {categoryFields.includes(categoryDescFields.HOW_TO_GET_THERE) && (
        <OrangeToolTip title="Where it is" arrow placement="right">
          <div className={classes.desc__box}>
            <h4 className={classes.descHeading}>How to get there?</h4>

            {display === true ? (
              <ReactQuill
                className={classes.desc__input}
                modules={{ toolbar: false }}
                readOnly={true}
                placeholder="How to get there?"
                value={description?.HowToGetThere || ""}
                defaultValue={description?.HowToGetThere || ""} // pass false to use minimal theme
              >
                <div className="my-editing-area" />
              </ReactQuill>
            ) : (
              <ReactQuill
                className={classes.desc__input}
                modules={{ toolbar: toolbarOptions }}
                onChange={handleChangeEditor}
                onFocus={(e) => setName("HowToGetThere")}
                defaultValue={description?.HowToGetThere || ""}
                theme={"snow"} // pass false to use minimal theme
              />
            )}
          </div>
        </OrangeToolTip>
      )}
      {/* 5.	Services */}
      {categoryFields.includes(categoryDescFields.SERVICES) && (
        <div className={classes.desc__box}>
          <h4 className={classes.descHeading}>Services</h4>
          <OrangeToolTip
            title="room, bathroom, pool, spam, gym, dining, drinking"
            arrow
            placement="right"
          >
            <TextareaAutosize
              className={classes.desc__input}
              placeholder="Services"
              value={description?.services}
              name="services"
              onChange={handleChange}
              readOnly={display}
              onClick={() =>
                setToolbar({
                  status: true,
                  section: "service",
                })
              }
              style={{
                fontWeight: textStyle.service.fontWeight,
                fontStyle: textStyle.service.fontStyle,
              }}
            />
          </OrangeToolTip>
        </div>
      )}

      {/* 6.	Known For?  */}
      {categoryFields.includes(categoryDescFields.KNOWN_FOR) && (
        <OrangeToolTip
          title="bullet points: specialities, events etc"
          arrow
          placement="right"
        >
          <div className={classes.desc__box}>
            <h4 className={classes.descHeading}>Known For</h4>
            {display === true ? (
              <ReactQuill
                className={classes.desc__input}
                modules={{ toolbar: false }}
                readOnly={true}
                placeholder="Known For?"
                value={description?.knownFor || ""}
                defaultValue={description?.knownFor || ""}
              >
                <div className="my-editing-area" />
              </ReactQuill>
            ) : (
              <ReactQuill
                className={classes.desc__input}
                modules={{ toolbar: toolbarOptions }}
                onChange={handleChangeEditor}
                onFocus={(e) => setName("knownFor")}
                defaultValue={description?.knownFor || ""}
                theme={"snow"} // pass false to use minimal theme
              />
            )}
          </div>
        </OrangeToolTip>
      )}
      {/* 7.	Things to do */}
      {categoryFields.includes(categoryDescFields.THINGS_TO_DO) && (
        <div className={classes.desc__box}>
          <h4 className={classes.descHeading}>Things to do</h4>
          <OrangeToolTip title="Things to do" arrow placement="right">
            <TextareaAutosize
              className={classes.desc__input}
              placeholder="Things to do"
              value={description?.thingsToDo}
              name="thingsToDo"
              onChange={handleChange}
              readOnly={display}
              onClick={() =>
                setToolbar({
                  status: true,
                  section: "toDo",
                })
              }
              style={{
                fontWeight: textStyle.toDo.fontWeight,
                fontStyle: textStyle.toDo.fontStyle,
              }}
            />
          </OrangeToolTip>
        </div>
      )}
      {/* 8.	Equipment */}
      {categoryFields.includes(categoryDescFields.EQUIPMENT) && (
        <div className={classes.desc__box}>
          <h4 className={classes.descHeading}>Equipment</h4>
          <OrangeToolTip
            title="If it applies (especially for wellness, sports, adventure)"
            arrow
            placement="right"
          >
            <TextareaAutosize
              className={classes.desc__input}
              placeholder="Equipment"
              value={description?.equipment}
              name="equipment"
              onChange={handleChange}
              readOnly={display}
              onClick={() =>
                setToolbar({
                  status: true,
                  section: "equipment",
                })
              }
              style={{
                fontWeight: textStyle.equipment.fontWeight,
                fontStyle: textStyle.equipment.fontStyle,
              }}
            />
          </OrangeToolTip>
        </div>
      )}

      {/* 9.	What’s specialty/ why we like it ? */}
      {categoryFields.includes(categoryDescFields.WHAT_SPECIALTY) && (
        <OrangeToolTip title="Remarkable spots" arrow placement="right">
          <div className={classes.desc__box}>
            <h4 className={classes.descHeading}>Specialty</h4>
            {display === true ? (
              <ReactQuill
                className={classes.desc__input}
                modules={{ toolbar: false }}
                readOnly={true}
                placeholder="What’s specialty/ why we like it ?"
                value={description?.whaSpecialty || ""}
                defaultValue={description?.whaSpecialty || ""}
              >
                <div className="my-editing-area" />
              </ReactQuill>
            ) : (
              <ReactQuill
                className={classes.desc__input}
                modules={{ toolbar: toolbarOptions }}
                onChange={handleChangeEditor}
                onFocus={(e) => setName("whaSpecialty")}
                defaultValue={description?.whaSpecialty || ""}
                theme={"snow"} // pass false to use minimal theme
              />
            )}
          </div>
        </OrangeToolTip>
      )}
      {/* 10.	Ingredients */}
      {categoryFields.includes(categoryDescFields.INGREDIENTS) && (
        <div className={classes.desc__box}>
          <h4 className={classes.descHeading}>Ingredients</h4>
          <OrangeToolTip title="Ingredients" arrow placement="right">
            <TextareaAutosize
              className={classes.desc__input}
              placeholder="Ingredients"
              value={description?.ingredients}
              name="ingredients"
              onChange={handleChange}
              readOnly={display}
              onClick={() =>
                setToolbar({
                  status: true,
                  section: "ingredients",
                })
              }
              style={{
                fontWeight: textStyle.ingredients.fontWeight,
                fontStyle: textStyle.ingredients.fontStyle,
              }}
            />
          </OrangeToolTip>
        </div>
      )}
      {/* 11.	Preparation */}
      {categoryFields.includes(categoryDescFields.PREPARATION) && (
        <div className={classes.desc__box}>
          <h4 className={classes.descHeading}>Preparation</h4>
          <OrangeToolTip title="Preparation" arrow placement="right">
            <TextareaAutosize
              className={classes.desc__input}
              placeholder="Preparation"
              value={description?.preparation}
              name="preparation"
              onChange={handleChange}
              readOnly={display}
              onClick={() =>
                setToolbar({
                  status: true,
                  section: "preparation",
                })
              }
              style={{
                fontWeight: textStyle.preparation.fontWeight,
                fontStyle: textStyle.preparation.fontStyle,
              }}
            />
          </OrangeToolTip>
        </div>
      )}
      {/* 12.	Collections */}
      {categoryFields.includes(categoryDescFields.COLLECTIONS) && (
        <div className={classes.desc__box}>
          <h4 className={classes.descHeading}>Collections</h4>
          <OrangeToolTip title="Collections" arrow placement="right">
            <TextareaAutosize
              className={classes.desc__input}
              placeholder="Collections"
              value={description?.collections}
              name="collections"
              onChange={handleChange}
              readOnly={display}
              onClick={() =>
                setToolbar({
                  status: true,
                  section: "collections",
                })
              }
              style={{
                fontWeight: textStyle.collections.fontWeight,
                fontStyle: textStyle.collections.fontStyle,
              }}
            />
          </OrangeToolTip>
        </div>
      )}
      {/* 13.History	 */}
      {categoryFields.includes(categoryDescFields.HISTORY) && (
        <OrangeToolTip title="History" arrow placement="right">
          <div className={classes.desc__box}>
            <h4 className={classes.descHeading}>History</h4>
            {display === true ? (
              <ReactQuill
                className={classes.desc__input}
                modules={{ toolbar: false }}
                readOnly={true}
                value={description?.history || ""}
                placeholder="History"
                defaultValue={description?.history}
              >
                <div className="my-editing-area" />
              </ReactQuill>
            ) : (
              <ReactQuill
                className={classes.desc__input}
                modules={{ toolbar: toolbarOptions }}
                onChange={handleChangeEditor}
                onFocus={(e) => setName("history")}
                defaultValue={description?.history}
                theme={"snow"} // pass false to use minimal theme
              />
            )}
          </div>
        </OrangeToolTip>
      )}

      {/* 14.	Landscape */}
      {categoryFields.includes(categoryDescFields.LANDSCAPE) && (
        <div className={classes.desc__box}>
          <h4 className={classes.descHeading}>Landscape</h4>
          <OrangeToolTip title="ex. ecosystem" arrow placement="right">
            <TextareaAutosize
              className={classes.desc__input}
              placeholder="Landscape"
              value={description?.landscape}
              name="landscape"
              onChange={handleChange}
              readOnly={display}
              onClick={() =>
                setToolbar({
                  status: true,
                  section: "landscape",
                })
              }
              style={{
                fontWeight: textStyle.landscape.fontWeight,
                fontStyle: textStyle.landscape.fontStyle,
              }}
            />
          </OrangeToolTip>
        </div>
      )}

      {/* 15.	Fauna */}
      {categoryFields.includes(categoryDescFields.FAUNA) && (
        <div className={classes.desc__box}>
          <h4 className={classes.descHeading}>Fauna</h4>
          <OrangeToolTip title="Fauna" arrow placement="right">
            <TextareaAutosize
              className={classes.desc__input}
              placeholder="Fauna"
              value={description?.fauna}
              name="fauna"
              onChange={handleChange}
              readOnly={display}
              onClick={() =>
                setToolbar({
                  status: true,
                  section: "fauna",
                })
              }
              style={{
                fontWeight: textStyle.fauna.fontWeight,
                fontStyle: textStyle.fauna.fontStyle,
              }}
            />
          </OrangeToolTip>
        </div>
      )}

      {/* 16.	Points of interest */}
      {categoryFields.includes(categoryDescFields.POINTS_OF_INTEREST) && (
        <OrangeToolTip title="Points of interest" arrow placement="right">
          <div className={classes.desc__box}>
            <h4 className={classes.descHeading}>Points of Interest</h4>
            {display === true ? (
              <ReactQuill
                className={classes.desc__input}
                modules={{ toolbar: false }}
                readOnly={true}
                placeholder="Points of interest"
                value={description?.pointsOfInterest || ""}
                defaultValue={description?.pointsOfInterest}
              >
                <div className="my-editing-area" />
              </ReactQuill>
            ) : (
              <ReactQuill
                className={classes.desc__input}
                modules={{ toolbar: toolbarOptions }}
                onChange={handleChangeEditor}
                onFocus={(e) => setName("pointsOfInterest")}
                defaultValue={description?.pointsOfInterest}
                theme={"snow"} // pass false to use minimal theme
              />
            )}
          </div>
        </OrangeToolTip>
      )}

      {/* 17.	Facilities */}
      {categoryFields.includes(categoryDescFields.FACILITIES) && (
        <OrangeToolTip
          title={
            postInfo.subCategory === "Mountains and Regional Parks" ||
            postInfo.subCategory === "Volcanoes"
              ? "Services available"
              : postInfo.subCategory === "Sea and Beaches"
              ? "Free beaches, lidos etc"
              : "Facilities"
          }
          arrow
          placement="right"
        >
          <div className={classes.desc__box}>
            <h4 className={classes.descHeading}>Facilities</h4>

            {display === true ? (
              <ReactQuill
                className={classes.desc__input}
                modules={{ toolbar: false }}
                readOnly={true}
                placeholder="Facilities"
                value={description?.facilities || ""}
                defaultValue={description?.facilities}
              >
                <div className="my-editing-area" />
              </ReactQuill>
            ) : (
              <ReactQuill
                className={classes.desc__input}
                modules={{ toolbar: toolbarOptions }}
                onChange={handleChangeEditor}
                onFocus={(e) => setName("facilities")}
                defaultValue={description?.facilities}
                theme={"snow"} // pass false to use minimal theme
              />
            )}
          </div>
        </OrangeToolTip>
      )}

      {/* 18.	Current Function */}
      {categoryFields.includes(categoryDescFields.CURRENT_FUNCTION) && (
        <div className={classes.desc__box}>
          <OrangeToolTip title="Current Function" arrow placement="right">
            <h4 className={classes.descHeading}>Current Function</h4>
            <TextareaAutosize
              className={classes.desc__input}
              placeholder="Current Function"
              value={description?.currentFunction}
              name="currentFunction"
              onChange={handleChange}
              readOnly={display}
              onClick={() =>
                setToolbar({
                  status: true,
                  section: "currentFunc",
                })
              }
              style={{
                fontWeight: textStyle.currentFunc.fontWeight,
                fontStyle: textStyle.currentFunc.fontStyle,
              }}
            />
          </OrangeToolTip>
        </div>
      )}
      {/* 19.	Geology */}
      {categoryFields.includes(categoryDescFields.GEOLOGY) && (
        <OrangeToolTip
          title="You can list the history of the eruptions etc"
          arrow
          placement="right"
        >
          <div className={classes.desc__box}>
            <h4 className={classes.descHeading}>Geology</h4>
            {display === true ? (
              <ReactQuill
                className={classes.desc__input}
                modules={{ toolbar: false }}
                readOnly={true}
                value={description?.geology || ""}
                placeholder="Geology"
                defaultValue={description?.geology}
                theme={"snow"} // pass false to use minimal theme
              />
            ) : (
              <ReactQuill
                className={classes.desc__input}
                placeholder="Geology"
                modules={{ toolbar: toolbarOptions }}
                onChange={handleChangeEditor}
                onFocus={(e) => setName("geology")}
                defaultValue={description?.geology}
                theme={"snow"} // pass false to use minimal theme
              />
            )}
          </div>
        </OrangeToolTip>
      )}
      {/* 20.	Did you know? */}
      {categoryFields.includes(categoryDescFields.DID_YOU_KNOW) && (
        <OrangeToolTip
          title="opening hours, closing day etc"
          arrow
          placement="right"
        >
          <div className={classes.desc__box}>
            <h4 className={classes.descHeading}>Did you know?</h4>
            {display === true ? (
              <ReactQuill
                className={classes.desc__input}
                modules={{ toolbar: false }}
                readOnly={true}
                value={description?.didYouKnow || ""}
                placeholder="Did you know?"
                defaultValue={description?.didYouKnow}
              >
                <div className="my-editing-area" />
              </ReactQuill>
            ) : (
              <ReactQuill
                id="editableInput"
                className={classes.desc__input}
                modules={{ toolbar: toolbarOptions }}
                onChange={handleChangeEditor}
                onFocus={(e) => setName("didYouKnow")}
                defaultValue={description?.didYouKnow} // pass false to use minimal theme
              />
            )}
          </div>
        </OrangeToolTip>
      )}

      {/* 21. Quick	Info */}
      {categoryFields.includes(categoryDescFields.QUICK_INFO) && (
        <OrangeToolTip
          title="opening hours, closing day etc"
          arrow
          placement="right"
        >
          <div className={classes.desc__box}>
            <h4 className={classes.descHeading}>Quick Info</h4>
            {display === true ? (
              <ReactQuill
                id="editableInput"
                className={classes.desc__input}
                modules={{ toolbar: false }}
                readOnly={true}
                style={{ borderColor: "white" }}
                placeholder="Quick Info"
                value={description?.info || ""}
                defaultValue={description?.info}
              >
                <div className="my-editing-area" />
              </ReactQuill>
            ) : (
              <ReactQuill
                className={classes.desc__input}
                modules={{ toolbar: toolbarOptions }}
                onChange={handleChangeEditor}
                style={{ borderColor: "black" }}
                onFocus={(e) => setName("info")}
                defaultValue={description?.info}
                theme={"snow"} // pass false to use minimal theme
              />
            )}
          </div>
        </OrangeToolTip>
      )}

      {/* TOOLBAR */}
      {toolbar.status && (
        <div style={toolStyle.toolbar}>
          <span
            style={
              textStyle[toolbar.section].fontWeight === "bold"
                ? toolStyleActive.bold
                : toolStyle.bold
            }
            onClick={() => {
              const temp = { ...textStyle };
              temp[toolbar.section].fontWeight === "normal"
                ? (temp[toolbar.section].fontWeight = "bold")
                : (temp[toolbar.section].fontWeight = "normal");
              setStyle(temp);
            }}
          >
            B
          </span>
          <span
            style={
              textStyle[toolbar.section].fontStyle === "italic"
                ? toolStyleActive.italic
                : toolStyle.italic
            }
            onClick={() => {
              const temp = { ...textStyle };
              temp[toolbar.section].fontStyle === "normal"
                ? (temp[toolbar.section].fontStyle = "italic")
                : (temp[toolbar.section].fontStyle = "normal");
              setStyle(temp);
            }}
          >
            I
          </span>
          <span style={toolStyle.link}>link</span>
        </div>
      )}
    </div>
  );
};

export default CategoryDescFields;
