export const toolStyle = {

    toolbar: {
        backgroundColor: 'rgba(128, 128, 128, 0.281)',
        borderRadius: '2px',
        position: 'fixed',
        top: '88vh',
        left: '45vw',
        padding: '12px 8px',
        zIndex: '20',
        userSelect: 'none',
    },

    bold: {
        fontWeight: 'bold',
        borderTop: '2px solid grey',
        borderRight: '2px solid grey',
        borderLeft: '2px solid grey',
        borderBottom: '2px solid grey',
        padding: '5px 10px',
        fontSize: '22px',
        color: 'black',
        cursor: 'pointer',
    },

    italic: {
        fontStyle: 'italic',
        borderTop: '2px solid grey',
        borderBottom: '2px solid grey',
        padding: '5px 15px',
        fontSize: '22px',
        color: 'black',
        cursor: 'pointer',
    },

    link: {
        borderTop: '2px solid grey',
        borderRight: '2px solid grey',
        borderLeft: '2px solid grey',
        borderBottom: '2px solid grey',
        padding: '5px 15px',
        fontSize: '22px',
        color: 'black',
        cursor: 'pointer',
    }
}

export const toolStyleActive = {

    bold: {
        fontWeight: 'bold',
        borderTop: '2px solid grey',
        borderRight: '2px solid grey',
        borderLeft: '2px solid grey',
        borderBottom: '2px solid grey',
        padding: '5px 10px',
        fontSize: '22px',
        color: 'black',
        cursor: 'pointer',
        backgroundColor: 'grey',
    },

    italic: {
        fontStyle: 'italic',
        borderTop: '2px solid grey',
        borderBottom: '2px solid grey',
        padding: '5px 15px',
        fontSize: '22px',
        color: 'black',
        cursor: 'pointer',
        backgroundColor: 'grey',
    },

    link: {
        borderTop: '2px solid grey',
        borderRight: '2px solid grey',
        borderLeft: '2px solid grey',
        borderBottom: '2px solid grey',
        padding: '5px 15px',
        fontSize: '22px',
        color: 'black',
        cursor: 'pointer',
        backgroundColor: 'grey',
    }
}