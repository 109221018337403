import { Box, Card, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import StarRateIcon from "@material-ui/icons/StarRate";
const useStyles = makeStyles((theme) => ({
  ScoresDisplay: {
    padding: "2rem 2rem",
    marginTop: "1rem",
    boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.07)",
    borderRadius: " 20px",
    width: "32vw",
    height: "60vh",
    [theme.breakpoints.down("xs")]: {
      width: "90vw",
      marginTop: "0.5rem",
    },
  },
  ScoresDisplay1: {
    padding: "2rem 2rem",
    marginTop: "1rem",
    boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.07)",
    borderRadius: " 20px",
    width: "32vw",
    height: "44vh",
    [theme.breakpoints.down("xs")]: {
      width: "85vw",
      marginTop: "0.5rem",
    },
  },
  Title: {
    fontFamily: "Inter",
    fontSize: "14px",
    paddingLeft: "1rem",
    lineHeight: "17px",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0.2rem",
    },
  },
  subTitle: {
    fontFamily: "Inter",
    fontSize: "12px",
    paddingLeft: "1rem",
    lineHeight: "20px",
    color: "#555555",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0.2rem",
    },
  },
  TitleUnderLine: {
    borderBottom: "2px solid #EB6E13",
    width: "65%",
    margin: "5px 0",
  },
  TitleWrapper: {
    display: "flex",
    width: "28vw",
    justifyContent: "flex-start",
    alignContent: "center",
    marginBottom: "5px",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-between",
      width: "70vw",
    },
  },
  split: {
    display: "flex",
    flexDirection: "column",
    width: "23vw",
    [theme.breakpoints.down("xs")]: {
      width: "40vw",
    },
  },
  icon1: {
    backgroundColor: "#FFF4EE",
    width: "40px",
    height: "40px",
    borderRadius: "18px",
    [theme.breakpoints.down("xs")]: {
      width: "30px",
      height: "30px",
    },
  },
  icon2: {
    color: "#FFBB0C",
    width: "30px",
    height: "30px",
    border: "1px solid #999999",
    borderRadius: "50%",
  },
}));

export function Title({ title, subTitle }) {
  const classes = useStyles();
  return (
    <>
      <div className={classes.TitleWrapper}>
        <BorderColorIcon className={classes.icon1} />
        <div className={classes.split}>
          <Typography className={classes.Title}>{title}</Typography>
          <Typography className={classes.subTitle}>{subTitle}</Typography>
        </div>
        <StarRateIcon classes={{ root: classes.icon2 }} />
      </div>
    </>
  );
}

export default function ScoreCard({ xsSize, mdSize, children, card }) {
  const classes = useStyles();
  const card1 = card === true ? classes.ScoresDisplay : classes.ScoresDisplay1;
  return (
    <Grid item md={mdSize} xs={xsSize}>
      <Card className={card1}>
        <Box justifyContent="space-between" display="flex">
          {children}
        </Box>
      </Card>
    </Grid>
  );
}
