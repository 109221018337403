import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { db } from "../../firebase";
import firebase from "firebase";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import { useAuth } from "../../contexts/user";
import PieceOfNews from "./PieceOfNews";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "80px",
    marginLeft: "20%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "5%",
    },
  },
  paper: {
    display: "flex",
    justifyContent: "flex-start",
  },
  textBox: {
    display: "flex",
    flexDirection: "column",
    width: "30%",
    marginLeft: "20%",
  },
  textarea1: {
    marginBottom: "10px",
    border: "1px solid #eb6e13",
  },
  textarea2: {
    marginBottom: "10px",
    border: "1px solid #eb6e13",
  },
  button: {
    width: "50px",
    cursor: "pointer",
    padding: "5px 10px 5px 10px",
    backgroundColor: "#eb6e13",
    border: "none",
    color: "#fff",
  },
  alert: {
    width: "90%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  Wrapper: {
    padding: " 32px 24px",
    width: "515px",
  },

  Title: {
    fontFamily: "Inter",
    fontSize: "30px",
    fontWeight: "500",
    lineHeight: "36px",
    letterSpacing: "0em",
    textAlign: "left",
    marginBottom: "15px",
    paddingLeft: "25px",
  },
  textarea: {},
}));

export default function Announcement() {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [newsTitle, setNewsTitle] = useState("");
  const [newsDescription, setNewsDescription] = useState("");
  const [open, setOpen] = React.useState(false);
  const { email } = useAuth();

  const handleUpload = () => {
    db.collection("news")
      .add({
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        newsTitle: newsTitle,
        newsDescription: newsDescription,
      })
      .then((docRef) => {
        console.log("Document written with ID: ", docRef.id);
        setOpen(true);
        setTimeout(() => window.location.reload(), 3000);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
        alert("Error try again");
      });
  };

  useEffect(() => {
    db.collection("news")
      .orderBy("createdAt", "desc")
      .limit(3)
      .onSnapshot((snapshot) => {
        setData(snapshot.docs.map((doc) => ({ id: doc.id, item: doc.data() })));
      });
  }, []);

  return (
    <div className={classes.root}>
      {email === "editor@siciliamia.com" ? (
        <div className={classes.paper}>
          <h2 id="simple-modal-title">Post News</h2>
          <div className={classes.textBox}>
            <textarea
              type="text"
              rows="1"
              placeholder="Title"
              className={classes.textarea1}
              value={newsTitle}
              onChange={(e) => setNewsTitle(e.target.value)}
            ></textarea>
            <textarea
              type="text"
              rows="4"
              placeholder="Descripiton"
              className={classes.textarea2}
              value={newsDescription}
              onChange={(e) => setNewsDescription(e.target.value)}
            ></textarea>
            <button className={classes.button} onClick={handleUpload}>
              Post
            </button>
            <br />
            <Collapse in={open} className={classes.alert}>
              <Alert
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                News Posted successfully!
              </Alert>
            </Collapse>
          </div>
        </div>
      ) : null}

      {data.map((item) => (
        <PieceOfNews
          title={item.item.newsTitle}
          description={item.item.newsDescription}
        />
      ))}
    </div>
  );
}
