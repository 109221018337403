import React, { useContext } from "react";
import { TextField, CssBaseline } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import { OrangeToolTip } from "../../userInterfaceComponents/CustomUIComponents";
import GoogleAutoComplete from "../../userInterfaceComponents/GoogleAutoComplete";
import { PostDetailsContext } from "../../contexts/PostDetails";
import NotificationComponent from "../../userInterfaceComponents/NotificationComponent";
import TagAttributes from "./TagAttributes";
import PictueUpload from "./PictueUpload";
import CategoryDescFields from "./CategoryDescFields";
/**
 * PostAttributes  Functional component
 *
 * @param {*} props
 * @returns
 */
const PostAttributes = (props) => {
  const { postInfo, setPostInfo } = useContext(PostDetailsContext);
  // handleImages
  const { classes, isEdit, notifyMessage } = props;
  let {
    attraction,
    rating,
    link,
    tripLink,
    gLink,
    tag,
    keywords,
    phoneN,
    mapLink,
    videoLink,
    address,
  } = postInfo ? postInfo : [];

  // eslint-disable-next-line no-unused-vars
  tag = tag ? tag : {};

  const setAddress = (value) => {
    postInfo
      ? setPostInfo({ ...postInfo, address: value })
      : setPostInfo({ address: value });
  };

  /**
   *
   * @param {*} e
   */
  const handleChange = (e) => {
    let { name, value } = e.target;
    postInfo
      ? setPostInfo({ ...postInfo, [name]: value })
      : setPostInfo({ [name]: value });
  };

  // const handleDeleteImage = (evt, index) => {
  //   photoUrl = photoUrl.filter((ele, idx) => idx !== index);
  //   setPostInfo({ ...postInfo, photoUrl });
  // };

  return (
    <div className={classes.createPostContainer}>
      <CssBaseline />
      <div className={classes.input__box}>
        <OrangeToolTip
          title="Write the place name in English followed by the original Italian name in brackets.
                  Ex. Segesta’s Free Thermal Baths (Terme libere di Segesta)"
          arrow
          placement="right"
        >
          <TextField
            id="input-with-icon-grid"
            className={classes.input1}
            label="Name of Attraction"
            value={attraction}
            name="attraction"
            onChange={handleChange}
          />
        </OrangeToolTip>
      </div>

      <div className={classes.input__box}>
        <Rating
          name="rating"
          className={classes.rating}
          value={rating}
          onChange={(evt, newValue) => {
            setPostInfo({ ...postInfo, [evt.target.name]: newValue });
          }}
        />

        <OrangeToolTip
          title="Remember to link the location’s official website.
              Ex. the official website for Duomo di San Giorgio in Ragusa: https://www.duomosangiorgioragusa.it/"
          arrow
          placement="right"
        >
          <input
            type="text"
            name="link"
            className={classes.input}
            value={link}
            onChange={handleChange}
            placeholder="Official website"
          ></input>
        </OrangeToolTip>
      </div>

      <div className={classes.input__box}>
        <OrangeToolTip
          title="Review link from Trip Advisor"
          arrow
          placement="right"
        >
          <input
            type="text"
            name="tripLink"
            className={classes.input}
            value={tripLink}
            onChange={handleChange}
            placeholder="Trip Advisor Review Link"
          ></input>
        </OrangeToolTip>

        <OrangeToolTip title="Link for Google Review" arrow placement="left">
          <input
            type="text"
            className={classes.input}
            value={gLink}
            name="gLink"
            onChange={handleChange}
            placeholder="Google Review Link"
          ></input>
        </OrangeToolTip>
      </div>

      <TagAttributes category={postInfo.category} isEdit={isEdit} />

      <OrangeToolTip
        title="Use one primary keyword or max 2 for each article. The title should contain one primary keyword only and then in the main body can build up to 4 secondary keywords. "
        arrow
        placement="top"
      >
        <textarea
          className={classes.desc__input}
          rows="4"
          placeholder="Keywords"
          name="keywords"
          value={keywords}
          onChange={handleChange}
        />
      </OrangeToolTip>
      <div className={classes.input__box}>
        <OrangeToolTip
          title="Make sure to double check the location’s address on google maps, that way you’ll never get lost ;)"
          arrow
          placement="right"
        >
          <GoogleAutoComplete
            inputStyle={classes.input}
            address={address ? address : ""}
            setAddress={setAddress}
          />
        </OrangeToolTip>

        <input
          type="text"
          name="phoneN"
          className={classes.input}
          value={phoneN}
          onChange={handleChange}
          placeholder="Phone No."
        ></input>

        <input
          type="text"
          className={classes.input}
          value={mapLink}
          name="mapLink"
          onChange={handleChange}
          placeholder="Google Map Link"
        ></input>
      </div>

      <div className={classes.upload__container}>
        {/*  UPLOAD THREE PICTURES */}
        <PictueUpload />
        {notifyMessage?.notify && (
          <NotificationComponent
            type={notifyMessage.type}
            message={notifyMessage.message}
          />
        )}
      </div>
      <div className={classes.input__box}>
        <OrangeToolTip
          title="If you decide to add a video to the article, remember to include the link."
          arrow
          placement="right"
        >
          <input
            type="text"
            name="videoLink"
            className={classes.input}
            placeholder="Link of the video"
            value={videoLink}
            onChange={handleChange}
          />
        </OrangeToolTip>
      </div>
      {/* CategoryDescFields */}
      <CategoryDescFields classes={classes} display={false} />
    </div>
  );
};

export default PostAttributes;
