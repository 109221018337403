import { Box, makeStyles } from "@material-ui/core";

import Score from "./Score";

const useStyles = makeStyles((theme) => ({
  first: {
    width: "100vw",
    marginTop: "60px",
    margin: "0 8rem",
    [theme.breakpoints.down("xs")]: {
      // width: "10%",
      margin: 0,
    },
  },
}));

function Rating() {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.first}>
        <Score />
      </Box>
    </>
  );
}

export default Rating;
