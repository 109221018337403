/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Chip, Button, Typography } from "@material-ui/core";

import { updateTags } from "../../services/ArticleService";

import {
  categories,
  accoVibeTags,
  accoBudgetTags,
  accoInterestTags,
  seasonTags,
  accoStyleTags,
  accoTravelerTags,
  peopleTags,
  foodVibeTags,
  foodBudgetTags,
  foodInterestTags,
  foodTravelerTags,
  artVibeTags,
  artBudgetTags,
  artInterestTags,
  artTravelerTags,
  natureVibeTags,
  natureBudgetTags,
  natureInterestTags,
  natureTravelerTags,
  expVibeTags,
  expBudgetTags,
  expInterestTags,
  expTravelerTags,
} from "../../constants";
import { PostDetailsContext } from "../../contexts/PostDetails";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  tagContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    margin: "20px",
  },
  dropdown: {
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  dropdown__container: {
    display: "flex",
    justifyContent: "center",
  },
  formControl: {
    width: "50%",
    minWidth: 200,
    background: "white",
    margin: "10px",
  },
  chipsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    marginTop: "20px",
    marginBottom: "10px",
  },

  chips: {
    margin: "3px",
    background: "#009B77",
  },

  chipsRow: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
    },
  },
  TagsOpen: {
    width: "30rem",
    height: "192vh",
    backgroundColor: "rgb(230,230,230)",
    position: "absolute",
    right: "0",
    top: "180px",
    zIndex: "1000",
    padding: "0 20px",
  },
  tagBtn: {
    margin: "10px",
  },
  tagTitle: {
    display: "flex",
    justifyContent: "space-between",
    margin: "15px 0",
  },

  selectBtn: {
    backgroundColor: "#eb6e13",
    color: "white",
    "&:hover": {
      backgroundColor: "#757575",
    },
  },
  closeBtn: {
    fontWeight: "bold",
    fontSize: "1rem",
  },
  tagsSide: {
    marginBottom: "1rem",
  },
}));

export default function TagAttributes({ category, isEdit }) {
  const classes = useStyles();
  const { postInfo, setPostInfo } = useContext(PostDetailsContext);
  const [openTags, setOpenTags] = useState(false);
  const [loading, setLoading] = useState(true);

  const [tags, setTags] = useState({
    Vibe: [],
    Budget: [],
    Interest: [],
    Style: [],
    Traveler: [],
    Season: [],
    People: [],
  });

  const getPostInfo = async () => {
    return await postInfo.tag;
  };

  const fetchData = async () => {
    if (getPostInfo() !== undefined) {
      await getPostInfo()
        .then((data) =>
          setTags({
            Vibe: data.Vibe,
            Budget: data.Budget,
            Interest: data.Interest,
            Style: data.Style,
            Traveler: data.Traveler,
            Season: data.Season,
            People: data.People,
          })
        )
        .catch((error) => {
          console.log(error);
        });
    }
  };

  React.useEffect(() => {
    fetchData();
    setLoading(false);
  }, [loading]);

  React.useEffect(() => {
    const temp = { ...postInfo };
    temp.tag = { ...tags };
    setPostInfo(temp);
  }, [tags]);

  let type1,
    type2,
    type3,
    type4,
    type5,
    type6,
    type7 = null;
  let tags1,
    tags2,
    tags3,
    tags4,
    tags5,
    tags6,
    tags7 = null;

  if (category) {
    switch (category.trim().toLowerCase()) {
      case categories.ACCOMMODATION.trim().toLowerCase():
        type1 = accoVibeTags;
        type2 = accoBudgetTags;
        type3 = accoInterestTags;
        type4 = accoStyleTags;
        type5 = accoTravelerTags;
        type6 = seasonTags;
        type7 = peopleTags;
        break;

      case categories.FOOD_AND_DRINK.trim().toLowerCase():
        type1 = foodVibeTags;
        type2 = foodBudgetTags;
        type3 = foodInterestTags;
        type4 = null;
        type5 = foodTravelerTags;
        type6 = seasonTags;
        type7 = peopleTags;
        break;

      case categories.ART_AND_CULTURE.trim().toLowerCase():
        type1 = artVibeTags;
        type2 = artBudgetTags;
        type3 = artInterestTags;
        type4 = null;
        type5 = artTravelerTags;
        type6 = seasonTags;
        type7 = peopleTags;
        break;

      case categories.NATURE.trim().toLowerCase():
        type1 = natureVibeTags;
        type2 = natureBudgetTags;
        type3 = natureInterestTags;
        type4 = null;
        type5 = natureTravelerTags;
        type6 = seasonTags;
        type7 = peopleTags;
        break;

      case categories.EXPERIENCE.trim().toLowerCase():
        type1 = expVibeTags;
        type2 = expBudgetTags;
        type3 = expInterestTags;
        type4 = null;
        type5 = expTravelerTags;
        type6 = seasonTags;
        type7 = peopleTags;
        break;

      default:
        type1 = [];
        type2 = [];
        type3 = [];
        type4 = [];
        type5 = [];
        type6 = [];
        type7 = [];
        break;
    }
  } else {
    type1 = [];
    type2 = [];
    type3 = [];

    type4 = [];
    type5 = [];
    type6 = [];
    type7 = [];
  }

  if (type1) {
    tags1 = type1.map((el) => (
      <Button
        style={
          tags.Vibe?.indexOf(el) === -1
            ? { backgroundColor: "white" }
            : { backgroundColor: "#eb6e13" }
        }
        className={classes.tagBtn}
        onClick={() => {
          const temp = { ...tags };
          temp.Vibe.indexOf(el) === -1
            ? temp.Vibe.push(el)
            : temp.Vibe.splice(temp.Vibe.indexOf(el), 1);
          setTags(temp);
        }}
        key={el}
        value={el}
      >
        {el}
      </Button>
    ));
  }

  if (type2) {
    tags2 = type2.map((el) => (
      <Button
        style={
          tags.Budget?.indexOf(el) === -1
            ? { backgroundColor: "white" }
            : { backgroundColor: "#eb6e13" }
        }
        className={classes.tagBtn}
        onClick={() => {
          const temp = { ...tags };
          temp.Budget.indexOf(el) === -1
            ? temp.Budget.push(el)
            : temp.Budget.splice(temp.Budget.indexOf(el), 1);
          setTags(temp);
        }}
        key={el}
        value={el}
      >
        {el}
      </Button>
    ));
  }
  if (type3) {
    tags3 = type3.map((el) => (
      <Button
        style={
          tags.Interest?.indexOf(el) === -1
            ? { backgroundColor: "white" }
            : { backgroundColor: "#eb6e13" }
        }
        className={classes.tagBtn}
        onClick={() => {
          const temp = { ...tags };
          temp.Interest.indexOf(el) === -1
            ? temp.Interest.push(el)
            : temp.Interest.splice(temp.Interest.indexOf(el), 1);
          setTags(temp);
        }}
        key={el}
        value={el}
      >
        {el}
      </Button>
    ));
  }
  if (type4) {
    tags4 = type4.map((el) => (
      <Button
        style={
          tags.Style?.indexOf(el) === -1
            ? { backgroundColor: "white" }
            : { backgroundColor: "#eb6e13" }
        }
        className={classes.tagBtn}
        onClick={() => {
          const temp = { ...tags };
          temp.Style.indexOf(el) === -1
            ? temp.Style.push(el)
            : temp.Style.splice(temp.Style.indexOf(el), 1);
          setTags(temp);
        }}
        key={el}
        value={el}
      >
        {el}
      </Button>
    ));
  }

  if (type5) {
    tags5 = type5.map((el) => (
      <Button
        style={
          tags.Traveler?.indexOf(el) === -1
            ? { backgroundColor: "white" }
            : { backgroundColor: "#eb6e13" }
        }
        className={classes.tagBtn}
        onClick={() => {
          const temp = { ...tags };
          temp.Traveler.indexOf(el) === -1
            ? temp.Traveler.push(el)
            : temp.Traveler.splice(temp.Traveler.indexOf(el), 1);
          setTags(temp);
        }}
        key={el}
        value={el}
      >
        {el}
      </Button>
    ));
  }

  if (type6) {
    tags6 = type6.map((el) => (
      <Button
        style={
          tags.Season?.indexOf(el) === -1
            ? { backgroundColor: "white" }
            : { backgroundColor: "#eb6e13" }
        }
        className={classes.tagBtn}
        onClick={() => {
          const temp = { ...tags };
          temp.Season.indexOf(el) === -1
            ? temp.Season.push(el)
            : temp.Season.splice(temp.Season.indexOf(el), 1);
          setTags(temp);
        }}
        key={el}
        value={el}
      >
        {el}
      </Button>
    ));
  }
  {
    if (type7) {
      tags7 = type7.map((el) => (
        <Button
          className={classes.tagBtn}
          style={
            tags.People?.indexOf(el) === -1
              ? { backgroundColor: "white" }
              : { backgroundColor: "#eb6e13" }
          }
          onClick={() => {
            const temp = { ...tags };
            temp.People.indexOf(el) === -1
              ? temp.People.push(el)
              : temp.People.splice(temp.People.indexOf(el), 1);
            setTags(temp);
          }}
          key={el}
          value={el}
        >
          {el}
        </Button>
      ));
    }
    const openTagsHandler = () => {
      setOpenTags(true);
    };
    const closeTagsHandler = (newTags, postId) => {
      updateTags(tags, postInfo.pid);
      setOpenTags(false);
    };
    // console.log(postInfo);

    return (
      <div className={classes.tagContainer}>
        <Button onClick={openTagsHandler} className={classes.selectBtn}>
          Select Tags
        </Button>
        {openTags && (
          <div className={classes.TagsOpen}>
            <div className={classes.tagTitle}>
              <Typography variant="h6" gutterBottom>
                SELECT TAGS
              </Typography>
              <Button onClick={closeTagsHandler} className={classes.closeBtn}>
                x
              </Button>
            </div>
            <div className={classes.tagsSide}>
              {tags1 && (
                <Typography variant="h6" gutterBottom>
                  VIBE
                </Typography>
              )}
              {tags1}
            </div>
            <div className={classes.tagsSide}>
              {tags2 && (
                <Typography variant="h6" gutterBottom>
                  BUDGET
                </Typography>
              )}
              {tags2}
            </div>
            <div className={classes.tagsSide}>
              {tags3 && (
                <Typography variant="h6" gutterBottom>
                  INTEREST
                </Typography>
              )}
              {tags3}
            </div>
            <div className={classes.tagsSide}>
              {tags4 && (
                <Typography variant="h6" gutterBottom>
                  STYLE
                </Typography>
              )}
              {tags4}
            </div>
            <div className={classes.tagsSide}>
              {tags5 && (
                <Typography variant="h6" gutterBottom>
                  TRAVELER
                </Typography>
              )}
              {tags5}
            </div>
            <div className={classes.tagsSide}>
              {tags6 && (
                <Typography variant="h6" gutterBottom>
                  SEASON
                </Typography>
              )}
              {tags6}
            </div>
            <div className={classes.tagsSide}>
              {tags7 && (
                <Typography variant="h6" gutterBottom>
                  PEOPLE
                </Typography>
              )}
              {tags7}
            </div>
          </div>
        )}

        <div className={classes.chipsContainer}>
          <div className={classes.chipsRow}>
            <p>Vibe:</p>
            {tags.Vibe?.map((value) => (
              <Chip
                key={value}
                label={value}
                color="primary"
                className={classes.chips}
              />
            ))}
          </div>
          <div className={classes.chipsRow}>
            <p>Budget:</p>
            {tags.Budget?.map((value) => (
              <Chip
                key={value}
                label={value}
                color="primary"
                className={classes.chips}
              />
            ))}
          </div>

          <div className={classes.chipsRow}>
            <p>Interest:</p>
            {tags.Interest?.map((value) => (
              <Chip
                key={value}
                label={value}
                color="primary"
                className={classes.chips}
              />
            ))}
          </div>
          {type4 === !null ? (
            <div className={classes.chipsRow}>
              <p>Style:</p>
              {tags.Style?.map((value) => (
                <Chip
                  key={value}
                  label={value}
                  color="primary"
                  className={classes.chips}
                />
              ))}
            </div>
          ) : null}
          <div className={classes.chipsRow}>
            <p>Types of Traveler:</p>
            {tags.Traveler?.map((value) => (
              <Chip
                key={value}
                label={value}
                color="primary"
                className={classes.chips}
              />
            ))}
          </div>
          <div className={classes.chipsRow}>
            <p>Season:</p>
            {tags.Season?.map((value) => (
              <Chip
                key={value}
                label={value}
                color="primary"
                className={classes.chips}
              />
            ))}
          </div>
          <div className={classes.chipsRow}>
            <p>No. of People:</p>
            {tags.People?.map((value) => (
              <Chip
                key={value}
                label={value}
                color="primary"
                className={classes.chips}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}
