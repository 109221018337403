import { SMIAUserTypes, postStatusTypes } from "../constants";

/**
 *  API that returns
 *  true : when logged in user is an employee
 *  false : when logged in user is a normal user
 * @param {*} currentUser
 * @returns
 */
export const isEmployee = (currentUser) => {
  return currentUser === SMIAUserTypes.SMIA_EDITOR_SUPERVISOR ||
    currentUser === SMIAUserTypes.SMIA_CONTENT_SUPERVISOR ||
    currentUser === SMIAUserTypes.SMIA_MARKETING_SUPERVISOR ||
    currentUser === SMIAUserTypes.SMIA_OFFICE ||
    /*currentUser === SMIAUserTypes.SMIA_EDITOR_MANAGER ||
    currentUser === SMIAUserTypes.SMIA_CONTENT_MANAGER ||
    currentUser === SMIAUserTypes.SMIA_MARKETING_MANAGER ||
    */
    currentUser === SMIAUserTypes.SMIA_IT_MANGER
    ? true
    : false;
};

export const isPostAuthor = (currentUser) => {};
/**
 * returns the next post status,
 * based on rejection/ Acceptance by the employess
 * @param {*} isAccept
 * @param {*} currentUser
 * @param {*} currentStatus
 * @returns
 */
export const getNextPostStatus = (isAccept, currentUser, currentStatus) => {
  let newStatus = -1;
  switch (currentUser) {
    case SMIAUserTypes.SMIA_EDITOR_SUPERVISOR:
      newStatus = isAccept
        ? postStatusTypes.EDITOR_SUPERVISOR_APPROVED
        : postStatusTypes.EDITOR_SUPERVISOR_REJECTED;
      break;
    case SMIAUserTypes.SMIA_CONTENT_SUPERVISOR:
      newStatus = isAccept
        ? postStatusTypes.CONTENT_SUPERVISOR_APPROVED
        : postStatusTypes.CONTENT_SUPERVISOR_REJECTED;
      break;
    case SMIAUserTypes.SMIA_MARKETING_SUPERVISOR:
      newStatus = isAccept
        ? postStatusTypes.MARKETING_SUPERVISOR_APPROVED
        : postStatusTypes.MARKETING_SUPERVISOR_REJECTED;
      break;
    /* case SMIAUserTypes.SMIA_EDITOR_MANAGER:
      newStatus = isAccept
        ? postStatusTypes.EDITOR_MANAGER_APPROVED
        : postStatusTypes.EDITOR_MANAGER_REJECTED;
      break;
    case SMIAUserTypes.SMIA_CONTENT_MANAGER:
      newStatus = isAccept
        ? postStatusTypes.CONTENT_MANGER_APPROVED
        : postStatusTypes.CONTENT_MANGER_REJECTED;
      break;
    case SMIAUserTypes.SMIA_MARKETING_MANAGER:
      newStatus = isAccept
        ? postStatusTypes.MARKETING_MANAGER_APPROVED
        : postStatusTypes.MARKETING_MANAGER_REJECTED;
      break;
      */
    case SMIAUserTypes.SMIA_IT_MANGER:
      newStatus = isAccept
        ? postStatusTypes.ADMIN_APPROVED
        : postStatusTypes.ADMIN_REJECTED;
      break;
    default:
      break;
  }
  return newStatus;
};

/**
 * Returns
 * true: if the current post can shoe 'Accept/ hide ' Buttons
 * false :  viceversa
 * @param {*} postInfo
 * @param {*} email
 * @returns
 */

export const canShowAccRejBtns = (postInfo, email) => {
  let res = false;
  if (!postInfo.isdraft) {
    switch (email) {
      case SMIAUserTypes.SMIA_EDITOR_SUPERVISOR:
        if (postInfo.postStatus === postStatusTypes.USER_CREATED) {
          res = true;
        } 
        break;
      case SMIAUserTypes.SMIA_CONTENT_SUPERVISOR:
        if (
          postInfo.postStatus === postStatusTypes.EDITOR_SUPERVISOR_APPROVED
        ) {
          res = true;
        }
        break;
      case SMIAUserTypes.SMIA_MARKETING_SUPERVISOR:
        if (postInfo.postStatus === postStatusTypes.CONTENT_MANGER_APPROVED) {
          res = true;
        }
        break;

      /*
      case SMIAUserTypes.SMIA_EDITOR_MANAGER:
        if (
          postInfo.postStatus === postStatusTypes.MARKETING_SUPERVISOR_APPROVED
        ) {
          res = true;
        }
        break;
      case SMIAUserTypes.SMIA_CONTENT_MANAGER:
        if (postInfo.postStatus === postStatusTypes.EDITOR_MANAGER_APPROVED) {
          res = true;
        }
        break;
      case SMIAUserTypes.SMIA_MARKETING_MANAGER:
        if (postInfo.postStatus === postStatusTypes.CONTENT_MANGER_APPROVED) {
          res = true;
        }
        break;
        */

      case SMIAUserTypes.SMIA_IT_MANGER:
        if (
          //  postInfo.postStatus === postStatusTypes.MARKETING_MANAGER_APPROVED
          postInfo.postStatus === postStatusTypes.MARKETING_SUPERVISOR_APPROVED
        ) {
          res = true;
        }
        break;
      default:
        res = false;
        break;
    }
  }
  return res;
};

/**
 * Returns the 'post status' ,
 * to fetch the posts in' Approved/Rejected' tab, based on the logged in user
 * @param {*} email
 * @returns
 */
export const getPostStatus = (email) => {
  let postStatus = -1;
  switch (email) {
    case SMIAUserTypes.SMIA_EDITOR_SUPERVISOR:
      postStatus = postStatusTypes.USER_CREATED;
      break;
    case SMIAUserTypes.SMIA_CONTENT_SUPERVISOR:
      postStatus = postStatusTypes.EDITOR_SUPERVISOR_APPROVED;
      break;
    case SMIAUserTypes.SMIA_MARKETING_SUPERVISOR:
      postStatus = postStatusTypes.CONTENT_SUPERVISOR_APPROVED;
      break;
    /* case SMIAUserTypes.SMIA_EDITOR_MANAGER:
      postStatus = postStatusTypes.MARKETING_SUPERVISOR_APPROVED;
      break;
    case SMIAUserTypes.SMIA_CONTENT_MANAGER:
      postStatus = postStatusTypes.EDITOR_MANAGER_APPROVED;
      break;
    case SMIAUserTypes.SMIA_MARKETING_MANAGER:
      postStatus = postStatusTypes.CONTENT_MANGER_APPROVED;
      break;
      */
    case SMIAUserTypes.SMIA_IT_MANGER:
      //postStatus = postStatusTypes.MARKETING_MANAGER_APPROVED;
      postStatus = postStatusTypes.MARKETING_SUPERVISOR_APPROVED;
      break;
    default:
      break;
  }
  return postStatus;
};

/**
 * Returns a list of accpeted and rejected  list
 *   and current post status
 * @param {*} postStatus
 * @returns
 */
export const acceptRejBy = (postStatus, userCreated) => {
  let accRejLst = { accLst: [], rejBy: "" };
  let popCnt = 0;
  switch (postStatus) {
    case postStatusTypes.EDITOR_SUPERVISOR_APPROVED:
      accRejLst.accLst.push("Fact Check Approved");
      break;

    case postStatusTypes.EDITOR_SUPERVISOR_REJECTED:
      accRejLst.rejBy = "Fact Check Denied";
      break;

    case postStatusTypes.CONTENT_SUPERVISOR_APPROVED:
      accRejLst.accLst.push("Fact Check Approved");
      accRejLst.accLst.push("Language Check Approved");
      break;

    case postStatusTypes.CONTENT_SUPERVISOR_REJECTED:
      accRejLst.accLst.push("Fact Check Approved");
      accRejLst.rejBy = "Language Check Denied";
      break;

    case postStatusTypes.MARKETING_SUPERVISOR_APPROVED:
      accRejLst.accLst.push("Fact Check Approved");
      accRejLst.accLst.push("Language Check Approved");
      accRejLst.accLst.push("SEO Check Approved");
      break;

    case postStatusTypes.MARKETING_SUPERVISOR_REJECTED:
      accRejLst.accLst.push("Fact Check Approved");
      accRejLst.accLst.push("Language Check Approved");
      accRejLst.rejBy = "SEO Check Denied";
      break;
    /*
    case postStatusTypes.EDITOR_MANAGER_APPROVED:
      accRejLst.accLst.push("Fact Check Approved");
      accRejLst.accLst.push("Language ChCK Approved");
      accRejLst.accLst.push("SEO Check Approved");
      accRejLst.accLst.push("Editor Manager Approved");
      break;

    case postStatusTypes.EDITOR_MANAGER_REJECTED:
      accRejLst.accLst.push("Fact Check Approved");
      accRejLst.accLst.push("Language ChCK Approved");
      accRejLst.accLst.push("SEO Check Approved");
      accRejLst.rejBy = "Editor Manager Rejected";
      break;

    case postStatusTypes.CONTENT_MANGER_APPROVED:
      accRejLst.accLst.push("Fact Check Approved");
      accRejLst.accLst.push("Language ChCK Approved");
      accRejLst.accLst.push("SEO Check Approved");
      accRejLst.accLst.push("Editor Manager Approved");
      accRejLst.accLst.push("Content Manager Approved");
      break;

    case postStatusTypes.CONTENT_MANGER_REJECTED:
      accRejLst.accLst.push("Fact Check Approved");
      accRejLst.accLst.push("Language ChCK Approved");
      accRejLst.accLst.push("SEO Check Approved");
      accRejLst.accLst.push("Editor Manager Approved");
      accRejLst.rejBy = "Content Manager Rejected";
      break;

    case postStatusTypes.MARKETING_MANAGER_APPROVED:
      accRejLst.accLst.push("Fact Check Approved");
      accRejLst.accLst.push("Language ChCK Approved");
      accRejLst.accLst.push("SEO Check Approved");
      accRejLst.accLst.push("Editor Manager Approved");
      accRejLst.accLst.push("Content Manager Approved");
      accRejLst.accLst.push("Marketing Manager Approved");
      break;

    case postStatusTypes.MARKETING_MANAGER_REJECTED:
      accRejLst.accLst.push("Fact Check Approved");
      accRejLst.accLst.push("Language ChCK Approved");
      accRejLst.accLst.push("SEO Check Approved");
      accRejLst.accLst.push("Editor Manager Approved");
      accRejLst.accLst.push("Content Manager Approved");
      accRejLst.rejBy = "Marketing Manager Rejected";
      break;
*/
    case postStatusTypes.ADMIN_APPROVED:
      accRejLst.accLst.push("Fact Check Approved");
      accRejLst.accLst.push("Language Check Approved");
      accRejLst.accLst.push("SEO Check Approved");
      /* accRejLst.accLst.push("Editor Manager Approved");
      accRejLst.accLst.push("Content Manager Approved");
      accRejLst.accLst.push("Marketing Manager Approved");
      */
      accRejLst.accLst.push("Admin Approved");
      break;

    case postStatusTypes.ADMIN_REJECTED:
      accRejLst.accLst.push("Fact Check Approved");
      accRejLst.accLst.push("Language Check Approved");
      accRejLst.accLst.push("SEO Check Approved");
      /*
      accRejLst.accLst.push("Editor Manager Approved");
      accRejLst.accLst.push("Content Manager Approved");
      accRejLst.accLst.push("Marketing Manager Approved");
      */
      accRejLst.rejBy = "Admin Rejected";
      break;

    default:
      break;
  }
  switch (userCreated) {
    case SMIAUserTypes.SMIA_EDITOR_SUPERVISOR:
      popCnt = 1;
      break;
    case SMIAUserTypes.SMIA_CONTENT_SUPERVISOR:
      popCnt = 2;
      break;
    case SMIAUserTypes.SMIA_MARKETING_SUPERVISOR:
      popCnt = 3;
      break;
    /*  case SMIAUserTypes.SMIA_EDITOR_MANAGER:
      popCnt = 4;
      break;
    case SMIAUserTypes.SMIA_CONTENT_MANAGER:
      popCnt = 5;
      break;
    case SMIAUserTypes.SMIA_MARKETING_MANAGER:
      popCnt = 6;
      break;
      */
    case SMIAUserTypes.SMIA_IT_MANGER:
      //popCnt = 7;
      popCnt = 4;
      break;
    default:
      break;
  }

  if (popCnt > 0) {
    do {
      accRejLst.accLst.shift();
      popCnt--;
    } while (popCnt >= 1);
  }
  return accRejLst;
};
/**
 * API returns the default posttype, based on the current user
 * @param {*} email
 * @returns
 */
export const getDefaultPostStatus = (email) => {
  let postStatus = -1;
  switch (email) {
    case SMIAUserTypes.SMIA_EDITOR_SUPERVISOR:
      postStatus = postStatusTypes.EDITOR_SUPERVISOR_APPROVED;
      break;
    case SMIAUserTypes.SMIA_CONTENT_SUPERVISOR:
      postStatus = postStatusTypes.CONTENT_SUPERVISOR_APPROVED;
      break;
    case SMIAUserTypes.SMIA_MARKETING_SUPERVISOR:
      postStatus = postStatusTypes.MARKETING_SUPERVISOR_APPROVED;
      break;
    /*  case SMIAUserTypes.SMIA_EDITOR_MANAGER:
      postStatus = postStatusTypes.EDITOR_MANAGER_APPROVED;
      break;
    case SMIAUserTypes.SMIA_CONTENT_MANAGER:
      postStatus = postStatusTypes.CONTENT_MANGER_APPROVED;
      break;
    case SMIAUserTypes.SMIA_MARKETING_MANAGER:
      postStatus = postStatusTypes.MARKETING_MANAGER_APPROVED;
      break;
      */
    case SMIAUserTypes.SMIA_IT_MANGER:
      postStatus = postStatusTypes.ADMIN_APPROVED;
      break;
    default:
      postStatus = postStatusTypes.USER_CREATED;
      break;
  }
  return postStatus;
};
