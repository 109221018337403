import React, { useContext, useState, useEffect, useRef } from "react";
import JoditEditor from "jodit-react";
import Rating from "@material-ui/lab/Rating";
import { makeStyles } from "@material-ui/core/styles";
import {
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Avatar,
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import Carousel from "../../userInterfaceComponents/Carousel";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { cardTypes } from "../../constants";
import { useAuth } from "../../contexts/user";
import EditPost from "../EditPost";
import { PostDetailsContext } from "../../contexts/PostDetails";
import {
  changePostStatus,
  deletePostFromDB,
  delImageFromStorage,
  addCommentToDB,
} from "../../services/ArticleService";
import {
  isEmployee,
  getNextPostStatus,
  canShowAccRejBtns,
} from "../../helper/postStatus";
import CommentUI from "../../userInterfaceComponents/CommentUI";
import CategoryDescFields from "../create_post/CategoryDescFields";
import Link from "@material-ui/core/Link";
import TagAttributes from "../create_post/TagAttributes";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#fff",
    padding: "30px",
    maxWidth: "100%",
    overflow: "auto",
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
      width: "100vw",
      height: "100vh",
      position: "relative",
    },
  },
  column1: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "20%",
    marginRight: "15px",
    [theme.breakpoints.down("xs")]: {
      // flexWrap: "wrap",
      flexDirection: "column",
      width: "100vw",
      justifyContent: "center",
      margin: "0 auto",
      marginTop: "-30px",
      height: "100vh",
    },
  },
  column2: {
    width: "65%",
    textAlign: "left",
    overflow: "auto",
    [theme.breakpoints.down("xs")]: {
      // flexWrap: "wrap",
      flexDirection: "column",
      width: "100vw",
      justifyContent: "center",
      margin: "0 auto",
      paddingTop: "1rem",
      height: "100vh",
    },
  },
  column3: {
    width: "5%",
    textAlign: "right",
  },
  avatar1: {
    color: "#ef8c45",
    width: "60px",
    height: "60px",
  },
  list: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    border: "1px solid #E4E4E4",
    borderRadius: "5px",
    lineHeight: "24px",
    padding: "10px",
    marginTop: "30px",
    width: "90%",
    "& p": {
      fontWeight: 500,
    },
    overflow: "auto",
  },
  listP: {
    fontWeight: "normal",
    color: "#373739",
  },
  heading: {
    textTransform: "uppercase",
  },
  place: {
    color: "#eb6e13",
    textTransform: "uppercase",
    fontWeight: "500",
  },
  middle: {
    width: "100%",
    marginBottom: "30px",
    textAlign: "left",
    marginTop: "40px",
  },

  buttonDiv: {
    display: "flex",
    marginBottom: "10px",
  },
  acceptButton: {
    border: "none",
    borderRadius: "5px",
    backgroundColor: "#00ff0a",
    padding: "5px 10px 5px 10px",
    color: "black",
    marginRight: "10px",
    cursor: "pointer",
  },
  rejectButton: {
    border: "none",
    borderRadius: "5px",
    backgroundColor: "#ff0000",
    padding: "5px 10px 5px 10px",
    color: "#fff",
    cursor: "pointer"
  },
  backButton: {
    color: "#eb6e13",
    border: "none",
    backgroundColor: "transparent",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
    [theme.breakpoints.down("xs")]: {
      position: "absolute",
      top: "1.5rem",
      width: "30px",
      justifyContent: "space-around",
      right: "2rem",
    },
  },
  editIcon: {
    [theme.breakpoints.down("xs")]: {
      position: "absolute",
      top: "-69rem",
      width: "30px",
      // justifyContent: "space-around",
      right: "-8rem",
    },
  },
  comment: {
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "40px",
  },
  CommentInputRoot: {
    display: "flex",
    marginTop: "40px",
    marginBottom: "40px",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      marginTop: "1rem",
      height: "10vh",
    },
  },
  CommentButton: {
    borderRadius: "4px",
    background: "#EB6E13",
    fontSize: "15px",
    fontWeight: "400",
    lineHeight: "20px",
    letterSpacing: "0px",
    color: "#FFF",
    marginTop: "10px",
    fontFamily: "Lato",
    textTransform: "none",
    float: "right",
  },
  avatar: {
    width: "70px",
    height: "70px",
    marginRight: "15px",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "1rem",
    },
  },
  Textarea: {
    width: "600px",
    border: "1px solid rgb(216, 216, 216)",
    marginRight: "15px",
    boxSizing: "border-box",
    borderRadius: "10px",
    padding: "15px",
  },
  input__box: {
    width: "100%",
    lineHeight: "25px",
  },
  descHeading: {
    textTransform: "uppercase",
    color: "#EB6E13",
    fontSize: "15px",
  },
  desc__input: {
    width: "100%",
    border: "none",
    textAlign: "justified",
    lineHeight: "25px",
    fontWeight: "400",
    fontSize: "14px",
  },
}));

export default function ArticleView({
  typeOfCard,
  id,
  onClose,
  postDetails,
  date,
}) {
  const classes = useStyles();
  const editor = useRef(null);
  const [editPost, setEditPost] = useState(false);
  const [deletePost, setDeletePost] = useState(false);
  const [comment, setComment] = useState("");
  const { currentUser } = useAuth();
  const { postInfo, setPostInfo } = useContext(PostDetailsContext);

  const config = {
    readonly: false,
  };

  useEffect(() => {
    setPostInfo(postDetails);
  }, [postDetails, setPostInfo]);

  /**
   *
   */
  const closeEditPost = () => {
    setEditPost(false);
  };

  /**
   *
   */
  const postComment = () => {
    let newCommentInfo = {
      createdBy: currentUser.email,
      commentTxt: comment,
    };

    // eslint-disable-next-line no-unused-vars
    let result = addCommentToDB(newCommentInfo, id)
      .then((res) => {
        postInfo
          ? postInfo.comments
            ? setPostInfo({
                ...postInfo,
                comments: [...postInfo.comments, { ...newCommentInfo }],
              })
            : setPostInfo({ ...postInfo, comments: [{ ...newCommentInfo }] })
          : setPostInfo({ comments: [...newCommentInfo] });
      })
      .catch((error) => {
        console.log("Error post status changed  ");
      });
  };

  /**
   *
   * @param {*} isAccept
   */
  const acceptRejectArticle = (isAccept) => {
    const newStatus = getNextPostStatus(
      isAccept,
      currentUser.email,
      postInfo.postStatus
    );
    setPostInfo({
      ...postInfo,
      postStatus: newStatus,
    });
    changePostStatus(newStatus, id)
      .then((res) => {
        console.log("post status changed successfully");
      })
      .catch((error) => {
        console.log("Error post status changed  ");
      });
  };

  /**
   *
   */
  const deletePostFromStorage = () => {
    let imgCnt = 0;
    postInfo.photoDetails.forEach((photo) => {
      delImageFromStorage(photo.photoUrl).then((res) => {
        imgCnt++;
        if (postInfo.photoDetails.length === imgCnt) {
          deletePostFromDB(id)
            .then((res) => console.log(" post successfully deleted"))
            .catch((Err) => console.log("erroe while deleting post"));
        }
      });
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.column1}>
        <AccountCircleIcon className={classes.avatar} />
        <p>{postInfo.username}</p>
        <p>{date}</p>
        <Rating name="rating" value={postInfo.rating} readOnly />
        <div className={classes.list}>
          <p>
            {" "}
            Category: <span className={classes.listP}>{postInfo.category}</span>
          </p>
          <p>
            {" "}
            Sub Category:{" "}
            <span className={classes.listP}>{postInfo.subCategory}</span>
          </p>
          <p>
            {" "}
            Region: <span className={classes.listP}>{postInfo.region}</span>
          </p>
          <p>
            {" "}
            Comune: <span className={classes.listP}>{postInfo.comune}</span>
          </p>
          <p>
            {" "}
            Attraction:{" "}
            <span className={classes.listP}>{postInfo.attraction}</span>
          </p>
          <p>
            {" "}
            Address: <span className={classes.listP}>{postInfo.address}</span>
          </p>
          <p>
            {" "}
            Phone Number:{" "}
            <span className={classes.listP}>{postInfo.phoneN}</span>
          </p>
          <p>
            {" "}
            Map Link:{" "}
            <Link href={postInfo.mapLink} target="_blank">
              Go to map
            </Link>
          </p>
          <p>
            {" "}
            Link:{" "}
            <Link href={postInfo.link} target="_blank">
              Go to page
            </Link>
          </p>
          <p>
            {" "}
            Trip Link:{" "}
            <Link href={postInfo.tripLink} target="_blank">
              Go to page
            </Link>
          </p>
          <p>
            {" "}
            G Link:{" "}
            <Link href={postInfo.gLink} target="_blank">
              Go to page
            </Link>
          </p>
        </div>
      </div>
      <div className={classes.column2}>
        <h2 className={classes.heading}>{postInfo.attraction}</h2>
        <p className={classes.place}>{postInfo.address}</p>

        <div className={classes.middle}>
          <Carousel pictures={postInfo.photoDetails} />
          <br />
          <h4 className={classes.descHeading}>
            {" "}
            Keywords: <span className={classes.listP}>{postInfo.keywords}</span>
          </h4>

          <h4 className={classes.descHeading}>
            {" "}
            Video Link:{" "}
            <Link href={postInfo.tripLink} target="_blank">
              Link to video
            </Link>{" "}
          </h4>
          <TagAttributes category={postInfo.category} />
          <CategoryDescFields classes={classes} display={true} />
        </div>
        {/*  Accept Reject Buttons  */}
        {typeOfCard === cardTypes.APPROVED_OR_REJECTED && (
            <div className={classes.buttonDiv}>
              <button
                className={classes.acceptButton}
                onClick={() => acceptRejectArticle(true)}
              >
                Accept
              </button>

              <button
                className={classes.rejectButton}
                onClick={() => acceptRejectArticle(false)}
              >
                Reject
              </button>
            </div>
          )}

        <Divider variant="fullWidth" />
        <br />
        <h2 className={classes.comment}>Comments</h2>
        <Divider variant="fullWidth" />
        <br />
        {/* Comments Section */}

        <div>
          {postInfo?.comments?.length > 0 &&
            postInfo.comments.map((eachComment, id) => (
              <CommentUI comment={eachComment} key={id} />
            ))}
          <Divider variant="fullWidth" />
          {isEmployee(currentUser.email) && (
            <Box className={classes.CommentInputRoot}>
              <Avatar className={classes.avatar1} src="" />
              <Box>
                {/* IMPLEMENT THE CODE HERE */}
                <JoditEditor
                  ref={editor}
                  value={comment}
                  config={config}
                  tabIndex={1}
                  onBlur={(e) => setComment(e)}
                />
                <Button
                  wait={"2000"}
                  onClick={() => postComment()}
                  className={classes.CommentButton}
                >
                  Comment
                </Button>
              </Box>
            </Box>
          )}
          <br />
        </div>
      </div>
      <div className={classes.column3}>
        <button onClick={onClose} className={classes.backButton}>
          <KeyboardBackspaceIcon className={classes.backButton1} />
          back
        </button>
        {(typeOfCard === cardTypes.DRAFTS ||
          (typeOfCard === cardTypes.APPROVED_OR_REJECTED &&
            isEmployee(currentUser.email)) ||
          (typeOfCard === cardTypes.IN_PROCESS &&
            currentUser.email === postInfo?.username)) && (
          <IconButton aria-label="settings">
            <EditIcon
              onClick={() => setEditPost(true)}
              className={classes.editIcon}
            />
          </IconButton>
        )}
        {typeOfCard === cardTypes.DRAFTS && (
          <IconButton aria-label="settings">
            <DeleteIcon
              onClick={() => setDeletePost(true)}
              className={classes.editIcon}
            />
          </IconButton>
        )}
      </div>
      <div>
        {editPost && (
          <EditPost
            classes={classes}
            id={id}
            open={editPost}
            handleClose={closeEditPost}
          />
        )}
        {deletePost && (
          <div>
            <Dialog open={deletePost} onClose={() => setDeletePost(false)}>
              <DialogTitle> Delete Article </DialogTitle>
              <DialogContent>
                <h3> Do you want to delete the Article </h3>
                <DialogActions>
                  <Button onClick={() => setDeletePost(false)} color="primary">
                    Cancel
                  </Button>
                  <Button
                    onClick={(evt) => deletePostFromStorage(evt)}
                    color="primary"
                  >
                    Delete
                  </Button>
                </DialogActions>
              </DialogContent>
            </Dialog>
          </div>
        )}
      </div>
    </div>
  );
}
