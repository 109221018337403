/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { OrangeToolTip } from "../../userInterfaceComponents/CustomUIComponents";
import NotificationComponent from "../../userInterfaceComponents/NotificationComponent";
import {
  categories,
  regions,
  agrigento,
  caltanissetta,
  catania,
  enna,
  ragusa,
  islands,
  siracusa,
  palermo,
  trapani,
  messina,
} from "./../../constants";
import { PostDetailsContext } from "../../contexts/PostDetails";
import { getSubCategoryList } from "../../helper/category.helper";

const getcomuneFromRegion = (region) => {
  switch (region.toLowerCase()) {
    case "agrigento":
      return agrigento;
    case "caltanissetta":
      return caltanissetta;
    case "catania":
      return catania;
    case "enna":
      return enna;
    case "ragusa":
      return ragusa;
    case "islands":
      return islands;
    case "siracusa":
      return siracusa;
    case "palermo":
      return palermo;
    case "trapani":
      return trapani;
    case "messina":
      return messina;
    default:
      return [];
  }
};

const Category = (props) => {
  const { postInfo, setPostInfo } = useContext(PostDetailsContext);
  const { classes, notifyMessage } = props;
  let { category, subCategory } = postInfo ? postInfo : [];
  category = category || "";
  subCategory = subCategory || "";
  useEffect(() => {
    const id = { ...postInfo };
    id.pid = "";
    //Region
    // eslint-disable-next-line no-unused-vars
    for (let [key, value] of Object.entries(regions)) {
      if (key === postInfo.region) {
        if (key === "TRAPANI") {
          id.pid += 1;
        }
        if (key === "PALERMO") {
          id.pid += 2;
        }
        if (key === "MESSINA") {
          id.pid += 3;
        }
        if (key === "AGRIGENTO") {
          id.pid += 4;
        }
        if (key === "CALTANISSETTA") {
          id.pid += 5;
        }
        if (key === "ENNA") {
          id.pid += 6;
        }
        if (key === "CATANIA") {
          id.pid += 7;
        }
        if (key === "RAGUSA") {
          id.pid += 8;
        }
        if (key === "SIRACUSA") {
          id.pid += 9;
        }
        if (key === "ISLANDS") {
          id.pid += 10;
        }
      }
    }
    // Comune
    for (let j = 0; j <= comuneList.length; j++) {
      if (comuneList[j] === postInfo.comune) {
        id.pid += [j + 1].toString();
        break;
      }
    }
    //Category
    if (postInfo.category === "Accommodation") {
      id.pid += "A";
    }
    if (postInfo.category === "Food and Drink") {
      id.pid += "B";
    }
    if (postInfo.category === "Art and Culture") {
      id.pid += "C";
    }
    if (postInfo.category === "Nature") {
      id.pid += "D";
    }
    if (postInfo.category === "Experiences") {
      id.pid += "E";
    }
    // SubCategory
    let year = new Date().toLocaleString([], { year: "numeric" });
    let month = new Date().toLocaleString([], { month: "2-digit" });
    let day = new Date().toLocaleString([], { day: "2-digit" });
    let hour = new Date().toLocaleString([], {
      hour: "2-digit",
      timeZone: "Europe/Rome",
    });
    let min = new Date().toLocaleString([], {
      minute: "2-digit",
      timeZone: "Europe/Rome",
    });
    let sec = new Date().toLocaleString([], {
      second: "2-digit",
      timeZone: "Europe/Rome",
    });

    for (let i = 0; i < subCategList.length; i++) {
      if (subCategList[i] === postInfo.subCategory) {
        id.pid += [i + 1].toString();
        id.pid += `${day}${month}${year}${hour}${min}${sec}`;
        break;
      }
    }

    setPostInfo(id);
  }, [postInfo.category, postInfo.subCategory]);

  /** For different sub-categories */
  let subCategList = [];
  let comuneList = null;
  /** For different Categories */
  let categoriesList = [];
  for (const [key, value] of Object.entries(categories)) {
    if (!Number.isNaN(Number(value))) {
      continue;
    }
    categoriesList.push({ id: key, name: value });
  }
  comuneList = postInfo.region ? getcomuneFromRegion(postInfo.region) : [];

  subCategList = category ? getSubCategoryList(category) : [];
  // console.log(postInfo);
  // console.log(categoriesList);
  // console.log(subCategList);
  const handleChange = (e) => {
    const { name, value } = e.target;
    postInfo
      ? setPostInfo({ ...postInfo, [name]: value })
      : setPostInfo({ [name]: value });
  };

  return (
    <div>
      <div className={classes.dropdown__container}>
        {/*  Dropdown list of categories  */}
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ minWidth: 300 }}
        >
          <InputLabel
            htmlFor="choose-category"
            className={classes.label}
            style={{ marginTop: "-5px" }}
            variant="filled"
          >
            Category
          </InputLabel>
          <Select
            defaultValue=""
            id="choose-category"
            name="category"
            value={category}
            onChange={handleChange}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {categoriesList.map((eachCateg) => (
              <MenuItem key={eachCateg.id} value={eachCateg.name}>
                {eachCateg.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <br />

      {/*  Dropdown list of sub-categories  */}
      <div className={classes.dropdown__container}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ minWidth: 300 }}
        >
          <InputLabel
            htmlFor="choose-sub-category"
            className={classes.label}
            style={{ marginTop: "-5px" }}
            variant="filled"
          >
            Sub Category
          </InputLabel>
          <Select
            id="choose-sub-category"
            name="subCategory"
            value={subCategory}
            onChange={handleChange}
          >
            <MenuItem>Choose...</MenuItem>
            {subCategList &&
              subCategList.map((eachSubCat) => (
                <OrangeToolTip
                  title={
                    eachSubCat === "Culture"
                      ? "Language classes, unique type of cinema, fest likeCarnivaletc."
                      : eachSubCat === "Food and drinks"
                      ? "Cooking classes, wine tasting, food testing, culinarytours,food festivals etc."
                      : eachSubCat === "Nature"
                      ? "Bird watching, volcanic excursions, cable cars etc."
                      : eachSubCat === "Adventure"
                      ? "Adventure parks, diving, kite & surfing, rock climbing,canoeing, caving, climbing, kayaking, rafting, horsebackriding, motorboating,bungee jumpingetc."
                      : eachSubCat === "Sports"
                      ? "Hiking, skiing, tennis, golf, bike tours, beach sports,beach sportsetc."
                      : eachSubCat === "Wellness"
                      ? "Spas, wellness treatments, yoga classes, beauty treatmentsetc."
                      : eachSubCat === "Events and Festivals"
                      ? "Concerts, classical plays, musicals, festivals, fairs,celebrations,events, temporary exhibitions etc."
                      : eachSubCat === "Shopping"
                      ? "Malls, local shops, flea markets, vintage markets,bookstores,boutiques, bazaars etc."
                      : eachSubCat === "Leisure"
                      ? "Zoos and aquariums, leisures parks, water parks, pools,sailing,cruises, yachting etc."
                      : ""
                  }
                  arrow
                  placement="right"
                  value={eachSubCat}
                >
                  <MenuItem key={eachSubCat} value={eachSubCat}>
                    {eachSubCat}
                  </MenuItem>
                </OrangeToolTip>
              ))}
          </Select>
        </FormControl>
      </div>
      {notifyMessage?.notify && (
        <NotificationComponent
          type={notifyMessage.type}
          message={notifyMessage.message}
        />
      )}
    </div>
  );
};

export default Category;
