import firebase from "firebase";
import { storage, db } from "../firebase";
import { ARTICLES_COLLECTION_NAME, postStatusTypes } from "../constants";
import { getDefaultPostStatus } from "../helper/postStatus";

/**
 *  API to create a new  post/article
 */
export const createArticle = (postInfo, currentUser) => {
  let keys = postInfo?.keywords
    ? postInfo?.keywords.split(",").filter((ele) => ele.trim().length > 0)
    : [];

  db.collection(ARTICLES_COLLECTION_NAME)
    .add({
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      isdraft: postInfo?.isdraft ? postInfo.isdraft : false,
      username: currentUser.email,
      attraction: postInfo?.attraction ? postInfo.attraction : "",
      address: postInfo?.address ? postInfo.address : "",
      fname: postInfo?.fname ? postInfo.fname : "",
      lname: postInfo?.lname ? postInfo.lname : "",
      sources: postInfo?.sources ? postInfo.sources : "",
      comune: postInfo?.comune ? postInfo.comune : "",
      region: postInfo?.region ? postInfo.region : "",
      category: postInfo?.category ? postInfo.category : "",
      subCategory: postInfo?.subCategory ? postInfo.subCategory : "",
      videoLink: postInfo?.videoLink ? postInfo.videoLink : "",
      rating: postInfo?.rating ? postInfo.rating : "",
      mapLink: postInfo?.mapLink ? postInfo.mapLink : "",
      link: postInfo?.link ? postInfo.link : "",
      tripLink: postInfo?.tripLink ? postInfo.tripLink : "",
      gLink: postInfo?.gLink ? postInfo.gLink : "",
      keywords: keys,
      tag: postInfo?.tag ? postInfo.tag : {},
      description: postInfo?.description ? postInfo.description : "",
      photoDetails: postInfo?.photoDetails ? postInfo.photoDetails : [],
      phoneN: postInfo?.phoneN ? postInfo.phoneN : "",
      postStatus: postInfo?.postStatus
        ? postInfo.postStatus
        :postStatusTypes.USER_CREATED
    })
    .then((postRef) => {
      // console.log(" the post successfully posted ", postRef.id);
      let generatedID = postRef.id.toString(); // Here we call the ID of the document
      console.log("ID OF CREATE POST: " + generatedID); // Here we display the generated document ID
      postRef.update({
        // Here we update the pid(Post ID) field in the new generated document
        pid: generatedID,
      });
    })
    .catch((err) => alert(err));
};

/**
 *
 * @param {*} id
 */
export const saveAsArticle = (id) => {
  return db
    .collection(ARTICLES_COLLECTION_NAME)
    .doc(id)
    .update({
      isdraft: false,
      postStatus: postStatusTypes.USER_CREATED,
    })
    .then((res) => " Post status changed successfully ")
    .catch((error) => `Error ${error}`);
};

/**
 * API that updates the Article.
 * @param {*} updatedPostInfo
 */
export const updateArticle = (updatedPostInfo, id) => {
  let { keywords } = updatedPostInfo;
  keywords = keywords
    ? typeof keywords === "string"
      ? keywords
          .trim()
          .split(",")
          .filter((ele) => ele.trim().length > 0)
      : keywords.length > 0
      ? keywords.join(",")
      : keywords
    : [];
  // console.log(updatedPostInfo.description);
  updatedPostInfo.keywords = keywords;
  return db
    .collection(ARTICLES_COLLECTION_NAME)
    .doc(id)
    .set({
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      isdraft: updatedPostInfo?.isdraft ? updatedPostInfo.isdraft : false,
      username: updatedPostInfo.username,
      attraction: updatedPostInfo?.attraction ? updatedPostInfo.attraction : "",
      address: updatedPostInfo?.address ? updatedPostInfo.address : "",
      fname: updatedPostInfo?.fname ? updatedPostInfo.fname : "",
      lname: updatedPostInfo?.lname ? updatedPostInfo.lname : "",
      sources: updatedPostInfo?.sources ? updatedPostInfo.sources : "",
      comune: updatedPostInfo?.comune ? updatedPostInfo.comune : "",
      region: updatedPostInfo?.region ? updatedPostInfo.region : "",
      category: updatedPostInfo?.category ? updatedPostInfo.category : "",
      subCategory: updatedPostInfo?.subCategory
        ? updatedPostInfo.subCategory
        : "",
      videoLink: updatedPostInfo?.videoLink ? updatedPostInfo.videoLink : "",
      rating: updatedPostInfo?.rating ? updatedPostInfo.rating : "",
      mapLink: updatedPostInfo?.mapLink ? updatedPostInfo.mapLink : "",
      link: updatedPostInfo?.link ? updatedPostInfo.link : "",
      tripLink: updatedPostInfo?.tripLink ? updatedPostInfo.tripLink : "",
      gLink: updatedPostInfo?.gLink ? updatedPostInfo.gLink : "",
      keywords: keywords,
      tag: updatedPostInfo?.tag ? updatedPostInfo.tag : {},
      description: {
        metaDescription: updatedPostInfo.description?.metaDescription
          ? updatedPostInfo.description.metaDescription
          : "",
        briefDescription: updatedPostInfo.description?.briefDescription
          ? updatedPostInfo.description.briefDescription
          : "",
        title: updatedPostInfo.description?.title
          ? updatedPostInfo.description.title
          : "",
        history: updatedPostInfo.description?.history
          ? updatedPostInfo.description.history
          : "",
        didYouKnow: updatedPostInfo.description?.didYouKnow
          ? updatedPostInfo.description.didYouKnow
          : "",
        HowToGetThere: updatedPostInfo.description?.HowToGetThere
          ? updatedPostInfo.description.HowToGetThere
          : "",
        services: updatedPostInfo.description?.services
          ? updatedPostInfo.description.services
          : "",
        knownFor: updatedPostInfo.description?.knownFor
          ? updatedPostInfo.description.knownFor
          : "",
        thingsToDo: updatedPostInfo.description?.thingsToDo
          ? updatedPostInfo.description.thingsToDo
          : "",
        equipment: updatedPostInfo.description?.equipment
          ? updatedPostInfo.description.equipment
          : "",
        whaSpecialty: updatedPostInfo.description?.whaSpecialty
          ? updatedPostInfo.description.whaSpecialty
          : "",
        ingredients: updatedPostInfo.description?.ingredients
          ? updatedPostInfo.description.ingredients
          : "",
        preparation: updatedPostInfo.description?.preparation
          ? updatedPostInfo.description.preparation
          : "",
        collections: updatedPostInfo.description?.collections
          ? updatedPostInfo.description.collections
          : "",
        landscape: updatedPostInfo.description?.landscape
          ? updatedPostInfo.description.landscape
          : "",
        fauna: updatedPostInfo.description?.fauna
          ? updatedPostInfo.description.fauna
          : "",
        pointsOfInterest: updatedPostInfo.description?.pointsOfInterest
          ? ""
          : "",
        facilities: updatedPostInfo.description?.facilities
          ? updatedPostInfo.description.facilities
          : "",
        currentFunction: updatedPostInfo.description?.currentFunction
          ? updatedPostInfo.description.currentFunction
          : "",
        geology: updatedPostInfo.description?.geology
          ? updatedPostInfo.description.geology
          : "",
        info: updatedPostInfo.description?.info
          ? updatedPostInfo.description.info
          : "",
      },
      photoDetails: updatedPostInfo?.photoDetails
        ? updatedPostInfo.photoDetails
        : [],
      phoneN: updatedPostInfo?.phoneN ? updatedPostInfo.phoneN : "",
      postStatus: updatedPostInfo.postStatus
    })
    .then((res) => res)
    .catch((error) => `Error occured when updating post info: ${error}`);
};

/**
 *
 * @param {*} id
 * @returns
 */
export const deletePostFromDB = (id) => {
  return db
    .collection(ARTICLES_COLLECTION_NAME)
    .doc(id)
    .delete()
    .then(function () {
      console.log("delete post info successfull");
    })
    .catch(function (error) {
      console.log(`Error occured when deleting post info: ${error}`);
    });
};

/**
 *
 * @param {*} newPostStatus
 * @param {*} id
 */
export const changePostStatus = (newPostStatus, id) => {
  return db
    .collection(ARTICLES_COLLECTION_NAME)
    .doc(id)
    .update({
      postStatus: newPostStatus,
    })
    .then((res) => " Post status changed successfully ")
    .catch((error) => `Error ${error}`);
};

/**
 *
 * @param {*} commentInfo
 * @param {*} id
 * @returns
 */
export const addCommentToDB = (newComment, id) => {
  console.log(" comment time stamp :", new Date());
  newComment.createdAt = new Date();
  return db
    .collection(ARTICLES_COLLECTION_NAME)
    .doc(id)
    .update({
      comments: firebase.firestore.FieldValue.arrayUnion(newComment),
    })
    .then((res) => res)
    .catch((error) => `Error ${error}`);
};

/** =================================  */
/**
 *
 * @param {*} picInfo
 * @returns
 */
export const uploadImageToStorage = (picInfo) => {
  if (picInfo.file) {
    let ref = storage.ref(`images/${picInfo.file.name}${Math.random()}`);
    return ref
      .put(picInfo.file)
      .then((snapshot) => snapshot)
      .catch((err) => err);
  }
};

/**
 * delete picture from Firebase storage
 * @param {*} url
 */
export const delImageFromStorage = (url) => {
  let pictureRef = storage.refFromURL(url);
  return pictureRef
    .delete()
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const updateTags = (newTags, id) => {
  return db
    .collection(ARTICLES_COLLECTION_NAME)
    .doc(id)
    .update({
      tag: newTags,
    })
    .then((res) => console.log(" Tags updated successfully "))
    .catch((error) => console.log(`Error ${error}`));
};
