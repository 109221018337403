import React, { useState, useContext, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Chip,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  CssBaseline,
} from "@material-ui/core";

import { useAuth } from "../contexts/user";
import { PostDetailsContext } from "../contexts/PostDetails";
import Rating from "@material-ui/lab/Rating";
import { postCreateSteps, MenuProps } from "../constants";
import Region from "./create_post/Region";
import Category from "./create_post/Category";
import PostAttributes from "./create_post/PostAttributes";
import Modal from "@material-ui/core/Modal";
import { createArticle } from "../services/ArticleService";

/** Styles  */
const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    height: "35vh",
    marginLeft: "20%",
    marginTop: "5%",
    background: "#fff",
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
      margin: "4rem 0 0 0rem",
      height: "93vh",
    },
  },

  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "2rem",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      gap: "1rem",
    },
  },
  backButton: {
    color: "black",
    marginRight: "40px",
    [theme.breakpoints.down("xs")]: {
      margin: "0 auto",
      width: "70vw",
      height: "7vh",
    },
  },
  nextButton: {
    background: "#eb6e13",
    color: "#fff",
    "&:hover": {
      backgroundColor: "grey",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "0 auto",
      width: "70vw",
      height: "7vh",
    },
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  logo: {
    width: "130px",
    margin: "20px",
  },
  inputPlace: {
    width: 300,
    borderWidth: "0 0 2px",
    borderColor: "#eb6e13",
    padding: "10px",
    fontWeight: "700",
    [theme.breakpoints.down("sm")]: {
      width: 200,
    },
  },
  title: {
    fontFamily: "Lato",
    width: 200,
    fontWeight: "800",
    color: "#eb6e13",
  },

  createPostContainer: {
    margin: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "80%",
    [theme.breakpoints.down("sm")]: {
      width: "10%",
    },
  },

  input__container: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },

  input__box: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderColor: "2px solid #EB6E13",
    width: "100%",
    marginBottom: "10px",
    marginTop: "10px",
    [theme.breakpoints.down("xs")]: {
      width: "70vw",
      height: "15vh",
      margin: "0 2.5rem",
      flexWrap: "wrap",
    },
  },
  inputBox__footer: {
    marginBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  input1: {
    width: 300,
    [theme.breakpoints.down("xs")]: {
      width: "98%",
      textAlign: "center",
    },
  },

  input: {
    marginBottom: "10px",
    width: "25%",
    padding: "8px",
    height: "20px",
    border: "2px solid #eb6e13",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      textAlign: "center",
      padding: "0.8rem",
    },
  },
  rating: {
    marginRight: "20px",
    [theme.breakpoints.down("xs")]: {
      padding: "0.8rem",
    },
  },
  desc__box: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderColor: "2px solid #EB6E13",
    width: "100%",
    marginBottom: "10px",
    marginTop: "10px",
    [theme.breakpoints.down("xs")]: {
      width: "70vw",
      height: "30vh",
      margin: "0 2.5rem",
      flexWrap: "wrap",
    },
  },
  desc__input: {
    marginTop: "30px",
    marginBottom: "10px",
    padding: "10px",
    border: "none",
    [theme.breakpoints.down("sm")]: {
      width: "100px",
    },
    [theme.breakpoints.down("xl")]: {
      width: "50%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "80vw",
      // height: "10vh",
      margin: "1rem 1.3rem",
    },
  },
  button: {
    margin: theme.spacing(3, 0, 2),
    color: "#fff",
    background: "#EB6E13",
    "&:hover": {
      backgroundColor: "grey",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  buttonDiv: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  upload__container: {
    display: "flex",
    margin: "10px",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "20px",
    marginBottom: "20px",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-between",
      padding: "1rem 5rem 0 2.5rem",
    },
  },
  imagePrev: {
    marginLeft: "20px",
    width: "150px",
    borderRadius: "1px",
  },
  selection__container: {
    margin: "0px 40px 50px 40px",
  },

  dropdown__container: {
    display: "flex",
    justifyContent: "center",
  },
  tagContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  formControl1: {
    width: "15%",
    background: "white",
  },
  formControl2: {
    width: "70%",
  },
  checkbox: {
    color: "#eb6e13",
    "&$checked": {
      color: "#eb6e13",
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  finalPage: {
    margin: "10px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "flex-start",
      justifyContent: "center",
    },
  },
  row: {
    display: "flex",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("xs")]: {
      width: "45%",
      height: "30vh",
      gap: "0.5rem",
      flexDirection: "column",
      marginBottom: "1rem",
    },
  },
  circleImg: {
    opacity: "0.7",
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
  },
  // eslint-disable-next-line no-dupe-keys
  modal: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
  },
  image: {
    display: "flex",
    justifyContent: "center",
  },
  imgcard: {
    width: "200px",
    height: "250px",
    [theme.breakpoints.down("sm")]: {
      margin: "1rem",
    },
  },
  media: {
    width: "200px",
    height: "100px",
  },
  descrLabel: {
    color: "#eb6e13",
    textTransform: "upperCase",
    fontWeight: "bold",
  },
}));

/**/
export default function CreatePost() {
  const classes = useStyles();
  // const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const { postInfo, setPostInfo } = useContext(PostDetailsContext);
  const steps = Object.entries(postCreateSteps).map((step) => step[1]);
  const { currentUser } = useAuth();
  const [notifyMessage, setNotifyMessage] = useState({ notify: false });

  /**
   **/
  const ImgUploadAlert = useCallback(() => {
    if (
      !postInfo ||
      !postInfo.photoDetails ||
      postInfo?.photoDetails?.length <= 0
      // && activeStep >= 2
    ) {
      setNotifyMessage({
        type: "error",
        message: "Please upload images !!",
        notify: true,
      });
      setTimeout(() => {
        setNotifyMessage({ notify: false, type: "", message: "" });
      }, 1000);
      // setSaveDraft(!saveDraft);
      return true;
    }
    return false;
  }, [postInfo]);
  const uploadPosts = useCallback(() => {
    // evt.preventDefault();
    if (!ImgUploadAlert()) {
      // updateDB();
      createArticle(postInfo, currentUser);
    }
  }, [ImgUploadAlert, postInfo, currentUser]);
  useEffect(() => {
    if (postInfo.isdraft !== undefined) {
      uploadPosts();
    }
  }, [postInfo.isdraft, uploadPosts]);

  const handleBack = () => {
    setActiveStep(activeStep - 1);
    // setPostInfo(postInfo.pid.split());
  };

  /**
   * Increments the step count
   */
  const handleNext = () => {
    if (
      (activeStep >= 2 && !ImgUploadAlert()) ||
      (activeStep === 1 && !categoryAlert()) ||
      activeStep < 1
    ) {
      setActiveStep(activeStep + 1);
    }
  };

  /**
   *
   * @returns
   */
  const categoryAlert = () => {
    if (!postInfo || !postInfo.category || !postInfo.subCategory) {
      console.log(" category is null ");
      setNotifyMessage({
        type: "error",
        message: "Please Choose Category and Sub-Category !!",
        notify: true,
      });
      setTimeout(() => {
        setNotifyMessage({ notify: false, type: "", message: "" });
      }, 1000);
      return true;
    }
    return false;
  };

  /**
   *
   * @returns
   */

  /**
   *
   * @param {*} evt
   */

  /**
   *
   * @returns
   */
  function Finalize() {
    return (
      <div className={classes.finalPage}>
        <div className={classes.row}>
          <h4>Region</h4>
          <h4>Comune</h4>
          <h4>Category</h4>
          <h4>Sub-Category</h4>
        </div>
        <div className={classes.row}>
          <Chip label={postInfo.region} />
          <Chip label={postInfo.comune} />
          <Chip label={postInfo.category} />
          <Chip label={postInfo.subCategory} />
        </div>
        <div className={classes.revPost}>
          <h4>Name of attraction: {postInfo.attraction}</h4>
          <div>
            <Rating
              name="simple-controlled"
              className={classes.rating}
              value={postInfo.rating}
            />
            <Typography>
              {" "}
              Link to other website: {postInfo.link}
              <br />
              {postInfo.gLink}
              <br />
              {postInfo.tripLink}
            </Typography>
          </div>
          <div>
            {postInfo.tag &&
              Object.entries(postInfo.tag).map((eachTag) => (
                <Chip label={eachTag[1].join(" ")} />
              ))}
          </div>
          <Typography component="span">{postInfo.keywords}</Typography>
          <div>
            <Chip label={postInfo.address} />
            <Chip label={postInfo.mapLink} />
            <Chip label={postInfo.phoneN} />
            <Chip label={postInfo.videoLink} />
          </div>
          {postInfo?.photoDetails?.length > 0 &&
            postInfo.photoDetails.map((eachPhoto) => {
              return (
                <Card className={classes.imgcard}>
                  <CardActionArea>
                    <CardMedia
                      className={classes.media}
                      image={eachPhoto.photoUrl}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        {eachPhoto.photoCredit}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {eachPhoto.photoDesc}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              );
            })}
          {postInfo.description?.title && (
            <>
              <Typography className={classes.descrLabel}>Title</Typography>
              <Typography>{postInfo.description.title}</Typography>
            </>
          )}
          {postInfo.description?.metaDescription && (
            <>
              <Typography className={classes.descrLabel}>
                Meta Description
              </Typography>
              <Typography>{postInfo.description.metaDescription}</Typography>
            </>
          )}
          {postInfo.description?.briefDescription && (
            <>
              <Typography className={classes.descrLabel}>
                Brief Description
              </Typography>
              <Typography>{postInfo.description.briefDescription}</Typography>
            </>
          )}
          {postInfo.description?.HowToGetThere && (
            <>
              <Typography className={classes.descrLabel}>
                How to get there?
              </Typography>
              <Typography>{postInfo.description.HowToGetThere}</Typography>
            </>
          )}
          {postInfo.description?.services && (
            <>
              <Typography className={classes.descrLabel}>Services</Typography>
              <Typography>{postInfo.description.services}</Typography>
            </>
          )}
          {postInfo.description?.knownFor && (
            <>
              <Typography className={classes.descrLabel}>Known For</Typography>
              <Typography>{postInfo.description.knownFor}</Typography>
            </>
          )}
          {postInfo.description?.thingsToDo && (
            <>
              <Typography className={classes.descrLabel}>
                Things to do
              </Typography>
              <Typography>{postInfo.description.thingsToDo}</Typography>
            </>
          )}
          {postInfo.description?.equipment && (
            <>
              <Typography className={classes.descrLabel}>Equipment</Typography>
              <Typography>{postInfo.description.equipment}</Typography>
            </>
          )}
          {postInfo.description?.whaSpecialty && (
            <>
              <Typography className={classes.descrLabel}>Specialty</Typography>
              <Typography>{postInfo.description.whaSpecialty}</Typography>
            </>
          )}
          {postInfo.description?.ingredients && (
            <>
              <Typography className={classes.descrLabel}>
                Ingredients
              </Typography>
              <Typography>{postInfo.description.ingredients}</Typography>
            </>
          )}
          {postInfo.description?.preparation && (
            <>
              <Typography className={classes.descrLabel}>
                Preparation
              </Typography>
              <Typography>{postInfo.description.preparation}</Typography>
            </>
          )}
          {postInfo.description?.collections && (
            <>
              <Typography className={classes.descrLabel}>
                Collections
              </Typography>
              <Typography>{postInfo.description.collections}</Typography>
            </>
          )}
          {postInfo.description?.history && (
            <>
              <Typography className={classes.descrLabel}>History</Typography>
              <Typography>{postInfo.description.history}</Typography>
            </>
          )}
          {postInfo.description?.landscape && (
            <>
              <Typography className={classes.descrLabel}>Landscape</Typography>
              <Typography>{postInfo.description.landscape}</Typography>
            </>
          )}
          {postInfo.description?.fauna && (
            <>
              <Typography className={classes.descrLabel}>Fauna</Typography>
              <Typography>{postInfo.description.fauna}</Typography>
            </>
          )}
          {postInfo.description?.pointsOfInterest && (
            <>
              <Typography className={classes.descrLabel}>
                Points of Interest
              </Typography>
              <Typography>{postInfo.description.pointsOfInterest}</Typography>
            </>
          )}
          {postInfo.description?.facilities && (
            <>
              <Typography className={classes.descrLabel}>Facilities</Typography>
              <Typography>{postInfo.description.facilities}</Typography>
            </>
          )}
          {postInfo.description?.currentFunction && (
            <>
              <Typography className={classes.descrLabel}>
                Current Function
              </Typography>
              <Typography>{postInfo.description.currentFunction}</Typography>
            </>
          )}
          {postInfo.description?.geology && (
            <>
              <Typography className={classes.descrLabel}>Geology</Typography>
              <Typography>{postInfo.description.geology}</Typography>
            </>
          )}
          {postInfo.description?.didYouKnow && (
            <>
              <Typography className={classes.descrLabel}>
                Did you know?
              </Typography>
              <Typography>{postInfo.description.didYouKnow}</Typography>
            </>
          )}
          {postInfo.description?.info && (
            <>
              <Typography className={classes.descrLabel}>Quick Info</Typography>
              <Typography>{postInfo.description.info}</Typography>
            </>
          )}
        </div>
      </div>
    );
  }

  /**
   *
   * @param {*} stepIndex
   * @returns
   */
  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return <Region classes={classes} />;
      case 1:
        return <Category classes={classes} notifyMessage={notifyMessage} />;
      case 2:
        return (
          <PostAttributes
            MenuProps={MenuProps}
            classes={classes}
            isEdit={false}
            notifyMessage={notifyMessage}
          />
        );
      case 3:
        return Finalize();
      default:
        return "Unknown stepIndex";
    }
  }

  let postView = (
    <div className={classes.body}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed
            </Typography>
            <Button>Reset</Button>
            {/*  onClick={handleReset}  */}
          </div>
        ) : (
          <div className={classes.cardz}>
            <Typography component="span" className={classes.instructions}>
              {getStepContent(activeStep)}
            </Typography>
            <div className={classes.buttonContainer}>
              <Button
                variant="contained"
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.backButton}
              >
                Back
              </Button>

              <Button
                variant="contained"
                disabled={
                  activeStep === 0 ||
                  activeStep === 1 ||
                  postInfo.isdraft !== undefined
                }
                className={classes.backButton}
                onClick={(evt) => {
                  if (!ImgUploadAlert()) {
                    postInfo
                      ? setPostInfo({ ...postInfo, isdraft: true })
                      : setPostInfo({ isdraft: true });
                  }
                  // uploadPosts(evt);
                }}
              >
                Save as draft
              </Button>

              {activeStep === steps.length - 1 ? (
                <Button
                  variant="contained"
                  className={classes.nextButton}
                  onClick={(evt) => {
                    postInfo
                      ? setPostInfo({ ...postInfo, isdraft: false })
                      : setPostInfo({ isdraft: false });
                    handleModalOpen(evt);
                  }}
                >
                  Upload
                </Button>
              ) : (
                <Button
                  variant="contained"
                  className={classes.nextButton}
                  onClick={handleNext}
                >
                  Next
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );

  function rand() {
    return Math.round(Math.random() * 20) - 10;
  }

  function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleModalOpen = () => {
    setOpen(true);
  };

  const body = (
    <div style={modalStyle} className={classes.modal}>
      <h2 id="simple-modal-title">Post Uploaded</h2>
      <br></br>
      <Button
        variant="contained"
        className={classes.nextButton}
        href="/entrypage"
      >
        New Entry
      </Button>{" "}
      &nbsp; &nbsp;
      <Button variant="contained" className={classes.nextButton} href="/">
        Home
      </Button>
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      {postView}
      <Modal
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}

// editorApproved: false,
// adminApproved: false,
// editorReject: false,
// marketingReject: false,
// marketingApproved: false,
// contentReject: false,
// contentApproved: false,
// adminReject: false,
// reject: false,
// editSupReject: false,
// editSupApproved: false,
// marktSupRej: false,
// marktSupApproved: false,
// langSupRej: false,
// langSupApproved: false,
