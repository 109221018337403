import React, { useEffect } from "react";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

const NotificationComponent = ({ type, message }) => {
  useEffect(() => {
    switch (type) {
      case "info":
        NotificationManager.info(message, " ", 500);
        break;
      case "success":
        NotificationManager.success(message, " ", 500);
        break;
      case "warning":
        NotificationManager.warning(message, " ", 500);
        break;
      case "error":
        NotificationManager.error(message, " ", 500);
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <NotificationContainer />
    </div>
  );
};
export default NotificationComponent;
