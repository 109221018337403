export const SMIAUserTypes = {
  SMIA_EDITOR_SUPERVISOR: "editor2@siciliamia.com", // Editor Supervisor
  SMIA_CONTENT_SUPERVISOR: "content2@siciliamia.com", // Content Supervisor
  SMIA_MARKETING_SUPERVISOR: "seo@siciliamia.com", // Marketing supervisor
  SMIA_OFFICE: "office@siciliamia.com", // office
  //SMIA_EDITOR_MANAGER: "editor@siciliamia.com", // Editor Manager
  //SMIA_CONTENT_MANAGER: "content@siciliamia.com", // Content Manager
  //SMIA_MARKETING_MANAGER: "marketing@siciliamia.com", // Marketing Manager
  SMIA_IT_MANGER: "it@siciliamia.com", // Admin
};

export const ARTICLES_COLLECTION_NAME = "articles";

export const cardTypes = {
  FINISHED: 1,
  APPROVED_OR_REJECTED: 2,
  IN_PROCESS: 3,
  DRAFTS: 4,
};

export const constantsList = {
  MAX_NUM_PICTURES_UPLOAD: 3,
};

export const trapani = [
  "Alcamo",
  "Buseto Palizzolo",
  "Calatafimi-Segesta",
  "Campobello di Mazara",
  "Castellammare del Golfo",
  "Castelvetrano",
  "Custonaci",
  "Erice",
  "Gibellina",
  "Marsala",
  "Mazara del Vallo",
  "Paceco",
  "Partanna",
  "Poggioreale",
  "Salaparuta",
  "Salemi",
  "Santa Ninfa",
  "San Vito Lo Capo",
  "Trapani",
  "Valderice",
  "Vita",
  "Petrosino",
];

export const palermo = [
  "Alia",
  "Alimena",
  "Aliminusa",
  "Altavilla Milicia",
  "Altofonte",
  "Bagheria",
  "Balestrate",
  "Baucina",
  "Belmonte Mezzagno",
  "Bisacquino",
  "Bolognetta",
  "Bompietro",
  "Borgetto",
  "Caccamo",
  "Caltavuturo",
  "Campofelice Di Fitalia",
  "Campofelice Di Roccella",
  "Campofiorito",
  "Camporeale",
  "Capaci",
  "Carini",
  "Castelbuono",
  "Casteldaccia",
  "Castellana Sicula",
  "Castronovo di Sicilia",
  "Cefalà Diana",
  "Cefalù",
  "Cerda",
  "Chiusa Sclafani",
  "Ciminna",
  "Cinisi",
  "Collesano",
  "Contessa Entellina",
  "Corleone",
  "Ficarazzi",
  "Gangi",
  "Geraci Siculo",
  "Giardinello",
  "Giuliana",
  "Godrano",
  "Gratteri",
  "Isnello",
  "Isola delle Femmine",
  "Lascari",
  "Lercara Friddi",
  "Marineo",
  "Mezzojuso",
  "Misilmeri",
  "Mondello",
  "Monreale",
  "Montelepre",
  "Montemaggiore Belsito",
  "Palazzo Adriano",
  "Palermo",
  "Partinico",
  "Petralia Soprana",
  "Petralia Sottana",
  "Piana degli Albanesi",
  "Polizzi Generosa",
  "Pollina",
  "Prizzi",
  "Roccamena",
  "Roccapalumba",
  "San Cipirello",
  "San Giuseppe Jato",
  "San Mauro Castelverde",
  "Santa Cristina Gela",
  "Santa Flavia",
  "Sciara",
  "Sclafani Bagni",
  "Termini Imerese",
  "Terrasini",
  "Torretta",
  "Trabia",
  "Trappeto",
  "Valledolmo",
  "Ventimiglia di Sicilia",
  "Vicari",
  "Villabate",
  "Villafrati",
  "Scillato",
  "Blufi",
];

export const messina = [
  "Alcara li Fusi",
  "Alì",
  "Alì Terme",
  "Antillo",
  "Barcellona Pozzo di Gotto",
  "Basicò",
  "Brolo",
  "Capizzi",
  "Capo d'Orlando",
  "Capri Leone",
  "Caronia",
  "Casalvecchio Siculo",
  "Castel di Lucio",
  "Castell'Umberto",
  "Castelmola",
  "Castroreale",
  "Cesarò",
  "Condrò",
  "Alcone",
  "Ficarra",
  "Fiumedinisi",
  "Floresta",
  "Fondachelli-Fantina",
  "Forza d'Agrò",
  "Francavilla di Sicilia",
  "Frazzanò",
  "Furci Siculo",
  "Furnari",
  "Gaggi",
  "Galati Mamertino",
  "Gallodoro",
  "Giardini-Naxos",
  "Gioiosa Marea",
  "Graniti",
  "Gualtieri Sicaminò",
  "Itala",
  "Letojanni",
  "Librizzi",
  "Limina",
  "Longi",
  "Malvagna",
  "Mandanici",
  "Mazzarrà Sant'Andrea",
  "Merì",
  "Messina",
  "Milazzo",
  "Militello Rosmarino",
  "Mirto",
  "Mistretta",
  "Mojo Alcantara",
  "Monforte San Giorgio",
  "Mongiuffi Melia",
  "Montagnareale",
  "Montalbano Elicona",
  "Motta Camastra",
  "Motta d'Affermo",
  "Naso",
  "Nizza di Sicilia",
  "Novara di Sicilia",
  "Oliveri",
  "Pace del Mela",
  "Pagliara",
  "Patti",
  "Pettineo",
  "Piraino",
  "Raccuja",
  "Reitano",
  "Roccafiorita",
  "Roccalumera",
  "Roccavaldina",
  "Roccella Valdemone",
  "Rodì Milici",
  "Rometta",
  "San Filippo del Mela",
  "San Fratello",
  "San Marco d'Alunzio",
  "San Pier Niceto",
  "San Piero Patti",
  "San Salvatore di Fitalia",
  "Santa Domenica Vittoria",
  "Sant'Agata di Militello",
  "Sant'Alessio Siculo",
  " Santa Lucia del Mela",
  "Sant'Angelo di Brolo",
  "Santa Teresa di Riva",
  "San Teodoro",
  "Santo Stefano di Camastra",
  "Saponara",
  "Savoca",
  "Scaletta Zanclea",
  "Sinagra",
  "Spadafora",
  "Taormina",
  "Torregrotta",
  "Tortorici",
  "Tripi",
  "Tusa",
  "Ucria",
  "Valdina",
  "Venetico",
  "Villafranca Tirrena",
  "Terme Vigliatore",
  "Acquedolci",
  "Torrenova",
];

export const agrigento = [
  "Agrigento",
  "Alessandria della Rocca",
  "Aragona",
  "Bivona",
  "Burgio",
  "Calamonaci",
  "Caltabellotta",
  "Camastra",
  "Cammarata",
  "Campobello di Licata",
  "Canicatti",
  "Casteltermini",
  "Castrofilippo",
  "Cattolica Eraclea",
  "Cianciana",
  "Comitini",
  "Favara",
  "Grotte",
  "Joppolo Giancaxio",
  "Licata",
  "Lucca Sicula",
  "Menfi",
  "Montallegro",
  "Montevago",
  "Naro",
  "Palma di Montechiaro",
  "Porto Empedocle",
  "Racalmuto",
  "Raffadali",
  "Ravanusa",
  "Realmonte",
  "Ribera",
  "Sambuca di Sicilia",
  "San Biagio Platani",
  "San Giovanni Gemini",
  "Sant' Angelo Muxaro",
  "Santa Elisabetta",
  "Santa Margherita di Belice",
  "Santo Stefano Quisquina",
  "Sciacca",
  "Siculiana",
  "Villafranca Sicula",
];

export const caltanissetta = [
  "Acquaviva Platani",
  "Bompensiere",
  "Butera",
  "Caltanissetta",
  "Campofranco",
  "Delia",
  "Gela",
  "Marianopoli",
  "Mazzarino",
  "Milena",
  "Montedoro",
  "Mussomeli",
  "Niscemi",
  "Resuttano",
  "San Cataldo",
  "Santa Caterina Villarmosa",
  "Serradifalco",
  "Sommantino",
  "Sutera",
  "Vallelunga Pratameno",
  "Villalba",
];

export const enna = [
  "Agira",
  "Aidone",
  "Assoro",
  "Barrafranca",
  "Calascibetta",
  "Catenanuova",
  "Centruripe",
  "Cerami",
  "Enna",
  "Gagliano Castelferrato",
  "Leonforte",
  "Nicosia",
  "Nissoria",
  "Piazza Armerina",
  "Pietraperzia",
  "Regalbuto",
  "Sperlinga",
  "Troina",
  "Valguarnera Caropepe",
  "Villarosa",
];

export const catania = [
  "Aci Bonaccorsi",
  "Aci Castello",
  "Aci Catena",
  "Acireale",
  "Aci Sant'Antonio",
  "Adrano",
  "Belpasso",
  "Biancavilla",
  "Bronte",
  "Calatabiano",
  "Caltagirone",
  "Camporotondo Etneo",
  "Castel di Judica",
  "Castiglione di Sicilia",
  "Catania",
  "Fiumefreddo di Sicilia",
  "Giarre",
  "Grammichele",
  "Gravina di Catania",
  "Licodia Eubea",
  "Linguaglossa",
  "Maletto",
  "Mascali",
  "Mascalucia",
  "Militello in Val di Catania",
  "Milo",
  "Mineo",
  "Mirabella Imbaccari",
  "Misterbianco",
  "Motta Sant'Anastasia",
  "Nicolosi",
  "Palagonia",
  "Paternò",
  "Pedara",
  "Piedimonte Etneo",
  "Raddusa",
  "Ramacca",
  "Randazzo",
  "Riposto",
  "San Cono",
  "San Giovanni la Punta",
  "San Gregorio di Catania",
  "San Michele di Ganzaria",
  "San Pietro Clarenza",
  "Sant'Agata li Battiat",
  "Sant'Alfio",
  "Santa Maria di Licodia",
  "Santa Venerina",
  "Scordia",
  "Trecastagni",
  "Tremestieri Etneo",
  "Valverde",
  "Viagrande",
  "Vizzini",
  "Zafferana Etnea",
  "Mazzarrone",
  "Maniace",
  "Ragalna",
];

export const ragusa = [
  "Acate",
  "Chiaramonte Gulfi",
  "Comiso",
  "Giarratana",
  "Ispica",
  "Modica",
  "Monterosso Almo",
  "Pozzallo",
  "Ragusa",
  "Santa Croce Camerina",
  "Scicli",
  "Vittoria",
];

export const siracusa = [
  "Augusta",
  "Avola",
  "Buccheri",
  "Buscemi",
  "Canicattini Bagni",
  "Carlentini",
  "Cassaro",
  "Ferla",
  "Floridia",
  "Francofonte",
  "Lentini",
  "Melilli",
  "Noto",
  "Pachino",
  "Palazzolo Acreide",
  "Rosolini",
  "Syracruse",
  "Solarino",
  "Sortino",
  "Portopalo di Capo Passero",
  "Priolo Gargallo",
];

export const islands = [
  "Pantelleria (Trapani)",
  "Alicudi (Messina)",
  "Filicudi (Messina)",
  "Salina (Messina)",
  "Lipari (Messina)",
  "Vulcano (Messina)",
  "Panarea (Messina)",
  "Stromboli (Messina)",
  "Favignana (Trapani)",
  "Marettimo (Trapani)",
  "Levanzo (Trapani)",
  "Lampedusa (Agrigento)",
  "Linosa (Agrigento)",
  "Ustica (Palermo)",
];

/** Post Creation Steps:  */
export const postCreateSteps = {
  STEP1: "Select Region & Comune",
  STEP2: "Select Category & Sub-Category",
  STEP3: "Create Post",
  STEP4: "Finalize & Upload",
};

/**  Step-1: REGIONS */
/** List of Regions */
export const regions = {
  AGRIGENTO: "Agrigento",
  CALTANISSETTA: "Caltanissetta",
  CATANIA: "Catania",
  ENNA: "Enna",
  RAGUSA: "Ragusa",
  ISLANDS: "Islands",
  SIRACUSA: "Siracusa",
  PALERMO: "Palermo",
  TRAPANI: "Trapani",
  MESSINA: "Messina",
};

/** Step-2: CATEGORY and SUB-CATEGORY  */
/** List of Categories */
export const categories = {
  ACCOMMODATION: "Accommodation",
  FOOD_AND_DRINK: "Food and Drink",
  ART_AND_CULTURE: "Art and Culture",
  NATURE: "Nature",
  EXPERIENCE: "Experiences",
  // ADVICE: "Advice",
};

export const subCategories = {
  // ---> for ACCOMMODATION category [ May be needed in future ]
  // HOTELS: "hotels",
  // HOSTELS: "hostels",
  // UNCONVENTIONAL_STAYS: "unconventional stays",
  // B_B: "B and B",
  // CAMPING: "camping",
  // RESORTS: "Resorts",
  // FARMSTAY_AGRITURISMO: "Farmstay (agriturismo)",
  // VILLAS: "Villas/Vacation home",

  // ---> for FOOD_AND_DRINK category [ needed in future]
  // RESTAURANTS: "restaurants",
  // BAR_CAFE: "Bar and cafe",
  // STREET_FOODS_TRUCKS: "Markets, street-food and food trucks",
  // NIGHTLIFE: "Nightlife",
  // ICE_CREAM_SHOPS: "Ice cream shops",
  // BAKERIES_PASTRIES: " Bakeries and pastry shops",
  // WINE_BARS: "Wine bars",
  RECEPIES: "Recipes",

  // ---> for ART_AND_CULTURE category
  MUSEUMS: "Museums",
  CATHEDRALS: "Church/Cathedral",
  MONUMENTS: "Monuments/Statues",
  ART_GALLERIES: "Art galleries",
  THEATRES: "Theatres",
  SICILY_CINEMA_LITERATURE: "Sicily in the cinema and literature",
  MYTHS_FOLKLORE: "Myths & Folklore",
  CULTURAL_PARKS: "Cultural parks (culture walk)",
  CASTLES: "Castles",
  HISTORICAL_SITES: "Unique historical sites",
  PALACES: "Palaces",
  ARCHEOLOGICAL_SITES: "Archeological sites",

  // ---> for NATURE category
  LAKES: "Lakes",
  MOUNTAINS_REGIONAL_PARKS: "Mountains and Regional Parks",
  RIVERS_WATERFALLS: "Rivers and Waterfalls",
  SEA_BEACHES: "Sea and Beaches",
  WILDLIFE_RESERVES: "Wildlife reserves",
  VOLCANOES: "Volcanoes",

  // ---> for EXPERIENCE category
  CULTURE: "Culture",
  FOOD_DRINKS: "Food and drinks",
  NATURE: "Nature",
  ADVENTURE: "Adventure",
  SPORTS: "Sports",
  WELLNESS: "Wellness",
  EVENTS_FESTIVALS: "Event and Festival",
  SHOPPING: "Shopping",
  LEISURE: "Leisure",
};

/*** Category description fields */
export const categoryDescFields = {
  TITLE: "Title",
  META_DESCRIPTION: "Meta Description",
  BRIEF_DESCRIPTION: "Brief Description",
  HOW_TO_GET_THERE: "How to get there",
  SERVICES: "Services",
  KNOWN_FOR: "Known for",
  WHAT_SPECIALTY: "What Specialty/ Why we lke it?",
  INGREDIENTS: "Ingredients",
  PREPARATION: "Preparation",
  COLLECTIONS: "Collections",
  HISTORY: "History",
  LANDSCAPE: "Landscape",
  FAUNA: "Fauna",
  POINTS_OF_INTEREST: "Points of Interest",
  FACILITIES: "Facilities",
  CURRENT_FUNCTION: "Current Function",
  GEOLOGY: "Geology",
  DID_YOU_KNOW: "Did you know",
  QUICK_INFO: "Quick Info",
};

export const accommodation = [
  "Hotels",
  "Hostels",
  "B&B",
  "Uncoventional/Unusual stay (homestay/houseswap/ cottage/castle)",
  "Camping",
  "Resorts",
  "Farmstay (Agriturismo)",
  "Villas/Vacation home",
];

export const food = [
  "Restaurants",
  "Bar & Café",
  "Markets and street food & foodtruck",
  "Nightlife",
  "Ice-cream shops",
  "Bakery & pastry shops",
  "Wine bars",
  "Recipes",
];

export const art = [
  "Museums",
  "Churches/Cathedrals",
  "Monuments/Statues",
  "Art galleries",
  "Theatres",
  "Sicily in the cinema and literature",
  "Myths & Folklore",
  "Cultural parks (culture walk)",
  "Castles",
  "Unique historical sites",
  "Archeological sites",
];

export const nature = [
  "Lakes",
  "Mountains and Regional Parks",
  "Rivers and Waterfalls",
  "Sea and Beaches",
  "Wildlife reserves",
  "Volcanoes",
];

export const advice = [
  "Transport/how to get there",
  "Taxi",
  "Busses",
  "Ferries",
  "Trains",
  "Driving",
  "Airlines",
  "Practical information (according to the whole region)",
  "Embassies and consulates",
  "Passports and visas",
  "Covid-19 guide",
  "Weather",
  "Safety info",
  "Currency and payment cards",
  "Health and insurance",
  "Tourism information office",
  "Public holidays",
];

export const exp = [
  "Culture",
  "Food and drinks",
  "Nature",
  "Adventure",
  "Sports",
  "Wellness",
  "Events and Festivals",
  "Shopping",
  "Leisure",
];

/** Step-3:  */
export const accoVibeTags = [
  "Sophisticated",
  "Casual",
  "Intimate",
  "Romantic",
  "Quiet",
  "Authentic ",
];

export const accoBudgetTags = [
  "Short term budget",
  "Mid-level flexi budget",
  "Strict budget backpackers",
  "Low budget",
  "Long term nomads",
  "Luxury",
  "Broke budget",
];
export const accoInterestTags = [
  "Beach",
  "Connect with local",
  "Local cuisine",
  "Wellness",
  "History",
  "Wine",
  "Customers top-rated",
  "Locally run",
  "Wedding locations",
  "Special offer",
  "Local products",
  "View point",
];

export const seasonTags = ["Summer", "Autumn", "Spring", "Winter"];

export const accoStyleTags = [
  "Casual chic",
  "Design",
  "Clubby atmosphere",
  "Local flavor",
  "Luxurious retreat",
];

export const accoTravelerTags = [
  "Business Travel",
  "Long term slow travel",
  "Event Travel",
  "Gay-friendly",
  "Physical disability -accessible",
  "Weekend break",
  "camping trip",
  "Volunteer travel",
  "Railway journey",
  "Eno-gastronomic tour",
  "Pet friendly",
  "Road trip",
  "Guided tour",
  "Independent travel",
  "Last minute",
  "Honey moon",
  "Small ship cruising",
  "Tailor made",
  "Solo female travel",
];

export const peopleTags = ["Group/friends", "Solo travel", "Couple", "Family"];

export const accoLodgingTags = ["Central", "Countryside", "Residential area"];

export const foodVibeTags = [
  "Romantic",
  "Casual",
  "Intimate",
  "Off-the-beaten-path",
  "Sophisticated",
  "Lively",
  "Authentic",
  "Sceney",
  "Quiet",
];

export const foodBudgetTags = [
  "Short term budget",
  "Mid-level flexi budget",
  "Strict budget backpackers",
  "Low budget",
  "Long term nomads",
  "Luxury",
  "Broke budget",
  "Free",
];

export const foodInterestTags = [
  "Must-see attractions",
  "Beach",
  "Connect with local",
  "Local cuisine",
  "Outdoor",
  "Seafood",
  "Local cuisine",
  "Wine",
  "Customers top-rated",
  "Upcoming events",
  "Locally run",
  "Wedding locations",
  "Special offer",
  "Local products",
  "Foodie - food tasting",
  "View point",
];

export const foodMealTags = [
  "Brunch",
  "Coffee",
  "Dessert",
  "Dinner",
  "Breakfast",
  "Lunch",
  "Quick bites",
];

export const foodTravelerTags = [
  "Eno-gastronomic tour",
  "Food testing",
  "Accessible-disability",
  "Gay-friendly",
  "Pet-friendly",
  "Weekend break",
  "Tailor made",
];

export const artVibeTags = [
  "Romantic",
  "Spiritual",
  "Curious",
  "Off-the-beaten-path",
];
export const artBudgetTags = ["Free"];

export const artInterestTags = [
  "Must-see attractions",
  "Outdoors",
  "Museums",
  "Culture",
  "History",
  "Customers top-rated",
  "Upcoming events",
  "Connect with locals",
  "Local guides",
  "Wedding locations",
  "Baroque lover",
  "Countryside",
  "UNESCO world heritage",
  "Outdoor theatre",
  "Viewpoint",
];

export const artTravelerTags = [
  "Long term slow travel",
  "Event Travel",
  "Gay-friendly",
  "Physical disability -accessible",
  "Weekend break",
  "Volunteer travel",
  "Eno-gastronomic tour",
  "Pet friendly",
  "Guided tour",
  "Independent travel",
  "Last minute",
  "Tailor made",
];

export const natureVibeTags = [
  "Romantic",
  "Spiritual",
  "Curious",
  "Romantic",
  "Off-the-beaten-path",
  "Adventurous",
  "Chill",
];

export const natureBudgetTags = ["free"];

export const natureInterestTags = [
  "Must-see attractions",
  "Beach",
  "Connect with local",
  "Outdoors",
  "Wellness",
  "Adventure",
  "Local guides",
  "Bird watching",
  "Local products",
  "Country side",
  "Viewpoint",
];

export const natureTravelerTags = [
  "Long term slow travel",
  "Gay-friendly",
  "Physical disability -accessible",
  "Weekend break",
  "Camping trip",
  "Solo female travel",
  "Pet friendly",
  "Road trip",
  "Guided tour",
  "Independent travel",
  "Last minute",
  "Honey moon",
  "Tailor made",
];

export const expVibeTags = [
  "Romantic",
  "Spiritual",
  "Curious",
  "Romantic",
  "Off-the-beaten-path",
  "Adventurous",
  "Chill",
  "Authentic",
  "Fast-paced",
  "Night owl",
];

export const expBudgetTags = [
  "Short term budget",
  "Mid-level flexi budget",
  "Strict budget backpackers",
  "Low budget",
  "Long term nomads",
  "Luxury",
  "Broke budget",
  "Free",
];

export const expInterestTags = [
  "Must-see attractions",
  "Beach",
  "Outdoor theatre",
  "Cooking classes",
  "Hiking",
  "Wellness",
  "Birdwatching",
  "Concerts",
  "Shopping",
  "History",
  "Wine",
  "Adventure",
  "Customers top-rated",
  "Upcoming events",
  "Locally run",
  "Connect with locals",
  "Local guides",
  "Sailing",
  "Wedding locations",
  "Wildlife",
  "Special offer",
  "Local products",
  "Foodie - food tasting",
  "Outdoor theatre",
  "Baroque lover",
  "Countryside",
  "Fashion addicted",
  "UNESCO world heritage",
  "Viewpoint",
  "Clothing",
  "Antique/Collectibles",
  "Cabaret",
  "Flea market/bazaars",
  "Celebrations and fairs",
  "Pubs",
  "Dance clubs",
  "Ceramics/glassware",
  "Zoos and aquariums",
  "Opera",
  "Puppet show",
  "Leisure parks",
  "Handicraft",
  "Jewelry",
];

export const expTravelerTags = [
  "Business Travel",
  "Long term slow travel",
  "Event Travel",
  "Gay-friendly",
  "Physical disability -accessible",
  "Weekend break",
  "Camping trip",
  "Volunteer travel",
  "Railway journay",
  "Eno-gastronomic tour",
  "Pet friendly",
  "Road trip",
  "Guided tour",
  "Independent travel",
  "Last minute",
  "Honey moon",
  "Small ship cruising",
  "Tailor made",
  "Solo female travel",
];

export const expGearTags = [
  "Beach packing",
  "Cruise Packing",
  "Ski Trip Packing",
  "Camping Packing",
  "Backpacking Packing",
  "Hiking packing",
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const postStatusTypes = {
  USER_CREATED: 0,
  EDITOR_SUPERVISOR_APPROVED: 1,
  EDITOR_SUPERVISOR_REJECTED: 2,
  CONTENT_SUPERVISOR_APPROVED: 3,
  CONTENT_SUPERVISOR_REJECTED: 4,
  MARKETING_SUPERVISOR_APPROVED: 5,
  MARKETING_SUPERVISOR_REJECTED: 6,
  /*EDITOR_MANAGER_APPROVED: 7,
  EDITOR_MANAGER_REJECTED: 8,
  CONTENT_MANGER_APPROVED: 9,
  CONTENT_MANGER_REJECTED: 10,
  MARKETING_MANAGER_APPROVED: 11,
  MARKETING_MANAGER_REJECTED: 12,
  */
  ADMIN_APPROVED: 7,
  ADMIN_REJECTED: 8,
};
