import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Box, CardMedia, Typography } from "@material-ui/core";
import Dot from "../assets/images/Dot.png";

const useStyles = makeStyles((theme) => ({
  SingleCommentRoot: {
    display: "flex",
    marginBottom: "40px",
    overflow: "auto",
    paddingBottom: "10px",
  },
  avatar: {
    width: "30px",
    height: "30px",
    marginRight: "15px",
  },
  date: {
    fontSize: "12px",
    fontWeight: "300",
    lineHeight: "20px",
    letterSpacing: "0",
    color: "#9B9FA4",
  },
  userNameAndDate: {
    display: "flex",
  },
  userName: {
    fontSize: "12px",
    fontWeight: "bold",
    lineHeight: "20px",
    letterSpacing: "0em",
  },
  Dot: {
    width: "3px",
    height: "3px",
    background: "#C4C4C4",
    margin: "auto 5px",
    borderRadius: "50%",
  },

  caption: {
    fontFamily: "Lato",
    fontSize: "15px",
    lineHeight: "15px",
    color: "#332F2F",
  },
}));

const CommentUI = ({ comment }) => {
  console.log(comment);
  const classes = useStyles();
  const date = new Date(comment.createdAt.seconds * 1000);

  const displayDate =
    date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();

  return (
    <Box className={classes.SingleCommentRoot}>
      <Avatar className={classes.avatar} />
      <Box>
        <Box className={classes.userNameAndDate}>
          <Typography className={classes.userName} variant="h6">
            {comment.createdBy}
          </Typography>
          {console.log("Date from db: ", displayDate)}
          <CardMedia className={classes.Dot} component="img" src={Dot} />
          <Typography className={classes.date}> {displayDate} </Typography>
        </Box>
        <Typography className={classes.caption} variant="body1">
          <div dangerouslySetInnerHTML={{ __html: comment.commentTxt }} />
        </Typography>
      </Box>
    </Box>
  );
};

export default CommentUI;
