import firebase from "firebase";


const firebaseConfig = {
  apiKey: "AIzaSyAa4vG0zmeMyksGKam1bYMRmAFt5bW3BWQ",
  authDomain: "siciliamia-ec766.firebaseapp.com",
  databaseURL: "https://siciliamia-ec766-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "siciliamia-ec766",
  storageBucket: "siciliamia-ec766.appspot.com",
  messagingSenderId: "896130097957",
  appId: "1:896130097957:web:5899ed21af0c5e384c30cc"
  };

 const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();
const storage = firebase.storage();
const auth = firebase.auth();
export { db, auth, storage };