import { Card, CardContent, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "40%",
    marginBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },

  content: {
    flex: "1 0 auto",
  },
}));

export default function PieceOfNews({ title, date, description, imageUrl }) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <Typography component="h5" variant="h5">
          {title}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {description}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          {date}
        </Typography>
      </CardContent>
    </Card>
  );
}
