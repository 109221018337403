/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Avatar,
  Container,
  Divider,
  CssBaseline,
  TextField,
  Typography,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { auth } from "../firebase";
import Logo from "../assets/images/logoSM.png";
import Circle from "../assets/images/Ellipse.png";

/** Styles  */
const useStyles = makeStyles((theme) => ({
  logo: {
    width: "130px",
    margin: "20px",
  },
  container: {
    backgroundColor: "#ffff",
    boxShadow: "0 8px 8px -6px grey",

    [theme.breakpoints.down("sm")]: {
      boxShadow: "none",
    },
  },
  paper: {
    marginTop: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "40px",
  },

  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#EB6E13",
    [theme.breakpoints.down("sm")]: {
      marginTop: "5px",
    },
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    backgroundColor: "#fff",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: "#fff",
    background: "#EB6E13",
    "&:hover": {
      backgroundColor: "grey",
    },
  },
  circleImg: {
    marginTop: "0px",
    backgroundSize: "cover",
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

export default function Login() {
  const classes = useStyles();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const signIn = (e) => {
    e.preventDefault();
    auth
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        history.push("/");
      })

      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        alert("Please check your email and password again!");
      });
  };

  return (
    <div className="container__login">
      <img src={Logo} className={classes.logo} alt="sicilia mia logo" />
      <Container component="main" maxWidth="xs" className={classes.container}>
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Divider variant="middle" />
          <form onSubmit={signIn} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="email"
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              id="password"
              autoComplete="current-password"
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
            </Button>
          </form>
        </div>
      </Container>

      <img className={classes.circleImg} src={Circle} alt="circle design"></img>
    </div>
  );
}
