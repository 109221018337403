import React, { useContext, useState, useEffect } from "react";
import { auth } from "../firebase";
import { db } from "../firebase";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  loading: {
    position: "fixed",
    top: "40%",
    left: "50%",
  },
  icon: {
    color: "#2196f3",
  },
}));

export const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export class Service {
  getAll() {
    return db;
  }

  create(tutorial) {
    return db.add(tutorial);
  }

  update(id, value) {
    return db.doc(id).update(value);
  }

  delete(id) {
    return db.doc(id).delete();
  }
}

export function AuthProvider({ children }) {
  // eslint-disable-next-line no-unused-vars
  const classes = useStyles();
  const [currentUser, setCurrentUser] = useState(null);
  const [email, setEmail] = useState(null);

  const authListener = () => {
    auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        setCurrentUser(currentUser);
        setEmail(currentUser.email);
      } else {
        setCurrentUser("");
        setEmail("");
      }
    });
  };

  useEffect(() => {
    authListener();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        email,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
