import React from "react";
import PlacesAutocomplete from "react-places-autocomplete";

const GoogleAutoComplete = React.forwardRef(({ address, setAddress }) => {
  // const [address, setAddress] = useState("");

  return (
    <div>
      <PlacesAutocomplete
        value={address}
        onChange={(value) => setAddress(value)}
        onSelect={(value) => setAddress(value)}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: "Address",
                className: "location-search-input",
              })}
            />
            <div>{loading && <div> ...loading </div>}</div>
            {suggestions.map((suggestion) => {
              const style = {
                backgroundColor: suggestion.active ? "#eb6e13cf" : "#fff",
              };
              return (
                <div {...getSuggestionItemProps(suggestion, { style })}>
                  {suggestion.description}
                </div>
              );
            })}
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
});

export default GoogleAutoComplete;
