import {
  categories,
  accommodation,
  food,
  art,
  nature,
  exp,
  advice,
  categoryDescFields,
  subCategories,
} from "../constants.js";

export const getSubCategoryList = (category) => {
  switch (category.toLowerCase()) {
    case "accommodation":
      return accommodation;
    case "food and drink":
      return food;
    case "art and culture":
      return art;
    case "nature":
      return nature;
    case "experiences":
      return exp;
    case "advice":
      return advice;
    default:
      return [];
  }
};

export const getCategoryFields = (category, subcategory) => {
  let fieldsList = [];
  if (category === null || subcategory === null) {
    return null;
  }
  fieldsList.push(
    categoryDescFields.TITLE,
    categoryDescFields.META_DESCRIPTION,
    categoryDescFields.BRIEF_DESCRIPTION,
    categoryDescFields.HOW_TO_GET_THERE,
    categoryDescFields.WHAT_SPECIALTY,
    categoryDescFields.HISTORY,
    categoryDescFields.DID_YOU_KNOW,
    categoryDescFields.QUICK_INFO
  );

  switch (category) {
    case categories.ACCOMMODATION:
      fieldsList.push(
        categoryDescFields.HOW_TO_GET_THERE,
        categoryDescFields.SERVICES
      );
      break;

    case categories.FOOD_AND_DRINK:
      subcategory === subCategories.RECEPIES
        ? fieldsList.push(
            categoryDescFields.INGREDIENTS,
            categoryDescFields.PREPARATION
          )
        : fieldsList.push(
            categoryDescFields.HOW_TO_GET_THERE,
            categoryDescFields.KNOWN_FOR
          );
      break;

    case categories.ART_AND_CULTURE:
      fieldsList.push(
        categoryDescFields.HOW_TO_GET_THERE,
        categoryDescFields.DID_YOU_KNOW
      );
      subcategory === subCategories.MUSEUMS
        ? fieldsList.push(categoryDescFields.COLLECTIONS)
        : fieldsList.push(categoryDescFields.HISTORY);
      if (subcategory === subCategories.THEATRES) {
        fieldsList.push(categoryDescFields.FACILITIES);
      }
      if (subcategory === subCategories.CASTLES) {
        fieldsList.push(
          categoryDescFields.QUICK_INFO
        );
      }
      break;

    case categories.NATURE:
      fieldsList.push(categoryDescFields.HOW_TO_GET_THERE);
      if (
        subcategory === subCategories.SEA_BEACHES ||
        subcategory === subCategories.MOUNTAINS_REGIONAL_PARKS ||
        subcategory === subCategories.VOLCANOES
      ) {
        fieldsList.push(categoryDescFields.FACILITIES);
      }
      if (
        subcategory === subCategories.VOLCANOES ||
        subcategory === subCategories.RIVERS_WATERFALLS ||
        subcategory === subCategories.LAKES
      ) {
        fieldsList.push(categoryDescFields.DID_YOU_KNOW);
      }
      if (
        subcategory === subCategories.MOUNTAINS_REGIONAL_PARKS ||
        subcategory === subCategories.RIVERS_WATERFALLS
      ) {
        fieldsList.push(categoryDescFields.POINTS_OF_INTEREST);
      }

      if (subcategory === subCategories.VOLCANOES) {
        fieldsList.push(categoryDescFields.GEOLOGY);
      }
      if (
        subcategory === subCategories.WILDLIFE_RESERVES ||
        subcategory === subCategories.RIVERS_WATERFALLS ||
        subcategory === subCategories.LAKES
      ) {
        fieldsList.push(categoryDescFields.LANDSCAPE);
      }
      if (
        subcategory === subCategories.WILDLIFE_RESERVES ||
        subcategory === subCategories.RIVERS_WATERFALLS
      ) {
        fieldsList.push(categoryDescFields.FAUNA);
      }
      break;

    case categories.EXPERIENCE:
      fieldsList.push(
        categoryDescFields.HOW_TO_GET_THERE,
        categoryDescFields.THINGS_TO_DO,
        categoryDescFields.EQUIPMENT
      );
      break;

    // May be neede in future based on requirement
    // case categories.ADVICE:
    //   break;
    default:
      break;
  }
  console.log(" categor.Helper: ", fieldsList);
  return fieldsList;
};
