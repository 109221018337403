import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
  Avatar,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import Image from "../../assets/images/icons/add-button-icon.png";
import { constantsList } from "../../constants";
import { PostDetailsContext } from "../../contexts/PostDetails";
import NotificationComponent from "../../userInterfaceComponents/NotificationComponent";
import {
  uploadImageToStorage,
  delImageFromStorage,
} from "../../services/ArticleService";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "90%",
    marginLeft: "5%",
    marginTop: "5%",
    background: "#fff",
    display: "flex",
    flexFlow: "row",
    justifyContent: "space-between",
    // [theme.breakpoints.down("xs")]: {
    //   margin: "1rem",
    //   width: "100%",
    // },
  },

  input__box: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderColor: "2px solid #EB6E13",
    width: "100%",
    marginBottom: "10px",
    marginTop: "10px",
  },
  input: {
    marginBottom: "10px",
    width: "90%",
    padding: "8px",
    height: "20px",
    border: "2px solid #eb6e13",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  fileinput: {
    display: "none",
  },
  imgList: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      gap: "1rem",
      flexDirection: "column",
      // margin: "1rem 0 0 1rem",
      alignItems: "center",
    },
  },
  imgcard: {
    width: "200px",
    height: "250px",
    [theme.breakpoints.down("xs")]: {
      width: "200px",
      // margin: "0 -1rem",
    },
  },
  emptyimgcard: {
    width: "200px",
    height: "200px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundImage: `url(${Image})`,
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("xs")]: {
      width: "80px",
      height: "80px",
      backgroundSize: "cover",
    },
  },
  media: {
    width: "200px",
    height: "100px",
  },
}));

/**
 *
 * @returns
 */
const PictueUpload = () => {
  const classes = useStyles();
  const { postInfo, setPostInfo } = useContext(PostDetailsContext);
  const [showPicUploadDlg, setShowPicUploadDlg] = useState(false);

  const emptyImagesCards = [];
  const uploadedImagesCards = [];

  const emptyUploads = postInfo?.photoDetails
    ? constantsList.MAX_NUM_PICTURES_UPLOAD - postInfo?.photoDetails.length
    : constantsList.MAX_NUM_PICTURES_UPLOAD;

  for (let i = 0; i < emptyUploads; i++) {
    emptyImagesCards.push(
      <Paper
        elevation={3}
        className={classes.emptyimgcard}
        key={i}
        onClick={() => setShowPicUploadDlg(true)}
      ></Paper>
    );
  }

  for (
    let i = 0;
    i < constantsList.MAX_NUM_PICTURES_UPLOAD - emptyUploads;
    i++
  ) {
    uploadedImagesCards.push(
      <Card className={classes.imgcard}>
        <CardActionArea>
          <CardMedia
            className={classes.media}
            image={postInfo.photoDetails[i].photoUrl}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {postInfo.photoDetails[i].photoCredit}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {postInfo.photoDetails[i].photoDesc}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <IconButton
            aria-label="delete"
            className={classes.margin}
            onClick={() => deletepicture(postInfo.photoDetails[i].photoUrl)}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </CardActions>
      </Card>
    );
  }
  // console.log(postInfo);

  /**
   *
   * @param {*} picInfo
   */
  const updatePictureInfo = (picInfo) => {
    uploadImageToStorage(picInfo)
      .then((snapshot) => {
        snapshot.ref.getDownloadURL().then((downloadURL) => {
          // console.log(" DOWNLOAD URL: ", downloadURL);
          let picDetails = {
            photoUrl: downloadURL,
            photoCredit: picInfo.photoCredit ? picInfo.photoCredit : "",
            photoDesc: picInfo.photoDesc ? picInfo.photoDesc : "",
          };

          postInfo
            ? postInfo.photoDetails
              ? setPostInfo({
                  ...postInfo,
                  photoDetails: [...postInfo.photoDetails, { ...picDetails }],
                })
              : setPostInfo({ ...postInfo, photoDetails: [{ ...picDetails }] })
            : setPostInfo({ photoDetails: [{ ...picDetails }] });
        });
      })
      .catch((error) => console.log("Error while uploasing icture to storage"));
  };

  /**
   *
   * @param {*} url
   */
  const deletepicture = (url) => {
    delImageFromStorage(url)
      .then((res) => {
        let postInfoPictures = postInfo.photoDetails;
        postInfoPictures = postInfoPictures.filter(
          (image) => image.photoUrl !== url
        );
        setPostInfo({
          ...postInfo,
          photoDetails: postInfoPictures,
        });
      })
      .catch((err) => console.log("Erro while deleting image from storage"));
  };

  return (
    <div className={classes.body}>
      {showPicUploadDlg && (
        <PicUploadDlg
          open={showPicUploadDlg}
          setShowPicUploadDlg={setShowPicUploadDlg}
          uploadPicture={updatePictureInfo}
        />
      )}
      <div className={classes.imgList}>
        {emptyImagesCards.length > 0 && emptyImagesCards.map((ele) => ele)}
        {uploadedImagesCards.length > 0 &&
          uploadedImagesCards.map((ele) => ele)}
      </div>
    </div>
  );
};
/**
 *
 * @param {*} param0
 * @returns
 */
const PicUploadDlg = ({ open, setShowPicUploadDlg, uploadPicture }) => {
  const classes = useStyles();
  const [pictureInfo, setPicturesInfo] = useState({});
  const [notifyMessage, setNotifyMessage] = useState({ notify: false });
  const [previewImg, setPreviewImg] = useState("");

  const handleUpload = () => {
    if (!pictureInfo.file) {
      setNotifyMessage({
        type: "warning",
        message: "Please upload images",
        notify: true,
      });
      setTimeout(() => {
        setNotifyMessage({ notify: false, type: "", message: "" });
      }, 5000);
    } else {
      uploadPicture(pictureInfo);
      setShowPicUploadDlg(false);
    }
  };

  const handleClose = () => {
    setShowPicUploadDlg(false);
  };

  return (
    <div>
      {notifyMessage?.notify && (
        <NotificationComponent
          type={notifyMessage.type}
          message={notifyMessage.message}
        />
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle> Upload picture </DialogTitle>
        <DialogContent>
          <div className={classes.root}>
            <input
              accept="image/*"
              className={classes.fileinput}
              type="file"
              id="contained-button-file"
              onChange={(evt) => {
                setPicturesInfo({
                  ...pictureInfo,
                  file: evt.target.files[0],
                });
                setPreviewImg(URL.createObjectURL(evt.target.files[0]));
              }}
            />
            <label htmlFor="contained-button-file">
              <Button variant="contained" color="primary" component="span">
                Upload
              </Button>
            </label>
            {/* <label>{pictureInfo?.file ? pictureInfo?.file.name : ""}</label> */}
            {pictureInfo?.file && (
              <Avatar alt="Image Choosen" src={previewImg} />
            )}
          </div>

          <div className={classes.input__box}>
            <input
              type="text"
              name="photoCred"
              className={classes.input}
              value={pictureInfo.photoCredit}
              onChange={(evt) =>
                setPicturesInfo({
                  ...pictureInfo,
                  photoCredit: evt.target.value,
                })
              }
              placeholder="Photo Credit "
            ></input>
          </div>

          <div className={classes.input__box}>
            <TextField
              id="outlined-multiline-static"
              label="Photos1 Description"
              multiline
              rows={4}
              variant="outlined"
              name="photoDesc"
              onChange={(evt) =>
                setPicturesInfo({
                  ...pictureInfo,
                  photoDesc: evt.target.value,
                })
              }
              value={pictureInfo.photoDesc}
            />
          </div>

          <DialogActions>
            <Button color="primary" onClick={handleClose}>
              Cancel
            </Button>
            <Button color="primary" onClick={() => handleUpload(pictureInfo)}>
              Upload
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PictueUpload;
