import React, { useState, useEffect, createContext } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import Login from "./components/Login";
import { AuthProvider } from "./contexts/user";
import PostDetails from "./contexts/PostDetails";
import CreatePost from "./components/CreatePost";
import { auth } from "./firebase";
import Sidebar from "./Sidebar";
import FetchPosts from "./components/list_post/FetchPosts";
import { cardTypes } from "./constants";
import RankingSystem from "./views/RankingSystem";
import Announcement from "./views/Announcement/Announce";
export const UserContext = createContext();

function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [searchText, setText] = useState("");

  useEffect(() => {
    auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        setCurrentUser(currentUser);
      } else {
        setCurrentUser("");
      }
    });
  }, []);

  return (
    <UserContext.Provider value={[searchText, setText]}>
      <AuthProvider>
        <Router>
          <div className="App">
            <Switch>
              {!currentUser ? (
                <Route exact path="/">
                  <Login />
                </Route>
              ) : (
                <PostDetails>
                  <Sidebar />

                  <Switch>
                    <Route exact path="/entrypage">
                      <CreatePost />
                    </Route>

                    <Route exact path="/draft">
                      <FetchPosts typeofCard={cardTypes.DRAFTS} />
                    </Route>

                    <Route exact path="/finished">
                      <FetchPosts typeofCard={cardTypes.FINISHED} />
                    </Route>

                    <Route exact path="/rejected">
                      <FetchPosts typeofCard={cardTypes.APPROVED_OR_REJECTED} />
                    </Route>

                    <Route exact path="/process">
                      <FetchPosts typeofCard={cardTypes.IN_PROCESS} />
                    </Route>

                    <Route exact path="/">
                      <CreatePost />
                    </Route>

                    <Route path="/ranking">
                      <RankingSystem />
                    </Route>

                    <Route path="/announcement">
                      <Announcement />
                    </Route>

                    {/* <Route path="/article">
                  <Article />
                </Route> */}
                  </Switch>
                </PostDetails>
              )}
            </Switch>
          </div>
        </Router>
      </AuthProvider>
    </UserContext.Provider>
  );
}

export default App;
