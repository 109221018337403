import React, { useContext } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import {
  regions,
  agrigento,
  caltanissetta,
  catania,
  enna,
  ragusa,
  islands,
  siracusa,
  palermo,
  trapani,
  messina,
} from "./../../constants";
import { PostDetailsContext } from "../../contexts/PostDetails";

/**
 * Returns the array of comune based on teh region
 * @param {*} region
 * @returns
 */
const getcomuneFromRegion = (region) => {
  switch (region.toLowerCase()) {
    case "agrigento":
      return agrigento;
    case "caltanissetta":
      return caltanissetta;
    case "catania":
      return catania;
    case "enna":
      return enna;
    case "ragusa":
      return ragusa;
    case "islands":
      return islands;
    case "siracusa":
      return siracusa;
    case "palermo":
      return palermo;
    case "trapani":
      return trapani;
    case "messina":
      return messina;
    default:
      return [];
  }
};

const Region = (props) => {
  const { postInfo, setPostInfo } = useContext(PostDetailsContext);
  const { classes } = props;
  let { region, comune } = postInfo ? postInfo : [];
  region = region || "";
  comune = comune || "";
  // useEffect(() => {
  //   const id = { ...postInfo };

  //   for (let i = 0; i < regionsList.length; i++) {
  //     if (regionsList[i].id === postInfo.region) {
  //       id.pid += i.toString();
  //       break;
  //     }
  //   }

  //   for (let j = 0; j < comuneList.length; j++) {
  //     if (comuneList[j] === postInfo.comune) {
  //       id.pid += j.toString();
  //       break;
  //     }
  //   }

  //   setPostInfo(id);
  // }, [postInfo.region, postInfo.comune]);
  // console.log(postInfo);

  /** For different comunes */
  let comuneList = null;

  /** For different Regions */
  let regionsList = [];
  for (const [key, value] of Object.entries(regions)) {
    if (!Number.isNaN(Number(value))) {
      continue;
    }
    regionsList.push({ id: key, name: value });
  }

  comuneList = region ? getcomuneFromRegion(region) : [];

  const handleChange = (e) => {
    const { name, value } = e.target;
    postInfo
      ? setPostInfo({ ...postInfo, [name]: value })
      : setPostInfo({ [name]: value });
  };

  return (
    <div>
      <div className={classes.dropdown__container}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ minWidth: 300 }}
        >
          {/*  Dropdown box for choosing REGION */}
          <InputLabel
            htmlFor="choose-region"
            className={classes.label}
            style={{ marginTop: "-5px" }}
            variant="filled"
          >
            Region
          </InputLabel>
          <Select
            id="choose-region"
            name="region"
            value={region}
            onChange={handleChange}
          >
            <MenuItem>Choose...</MenuItem>
            {regionsList.map((eachRegion) => (
              <MenuItem key={eachRegion.id} value={eachRegion.id}>
                {eachRegion.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <br />
      <div className={classes.dropdown__container}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ minWidth: 300 }}
        >
          {/*  Dropdown box for choosing comune */}
          <InputLabel
            htmlFor="choose-comune"
            className={classes.label}
            style={{ marginTop: "-5px" }}
            variant="filled"
          >
            Comune
          </InputLabel>
          <Select
            id="choose-comune"
            name="comune"
            value={comune}
            onChange={handleChange}
          >
            <MenuItem>Choose...</MenuItem>
            {comuneList &&
              comuneList.map((eachcomune) => (
                <MenuItem key={eachcomune} value={eachcomune}>
                  {eachcomune}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default Region;
