import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, alpha } from "@material-ui/core/styles";
import {
  Link,
  Drawer,
  AppBar,
  Divider,
  Toolbar,
  Typography,
  ListItem,
  ListItemIcon,
  List,
  IconButton,
  Tooltip,
  CssBaseline,
} from "@material-ui/core";
import { Menu, Close } from "@material-ui/icons";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import FinishIcon from "./assets/images/icons/finished.png";
import ProcessIcon from "./assets/images/icons/process.png";
import ApproveIcon from "./assets/images/icons/appr.png";
import DraftIcon from "./assets/images/icons/draft.png";
import PostIcon from "./assets/images/icons/post.png";
import { auth } from "./firebase";
import { SMIAUserTypes } from "./constants";
import { UserContext } from "./App";
import AnnounceIcon from "./assets/images/icons/announ.png";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",

    [theme.breakpoints.down("xs")]: {
      marginTop: "-5px",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  rightTools: {
    display: "flex",
    justifyContent: "space-between",
    width: "40%",
    [theme.breakpoints.down("xs")]: {
      width: "90vw",
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "column",
      height: "30vh",
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#fff",
    color: "black",
    width: "100%",

    [theme.breakpoints.down("xs")]: {
      width: "100%",
      zIndex: 1,
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },

  userBox: {
    alignItems: "center",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-end",
    },
  },
  email: {
    fontWeight: "600",
    textTransform: "uppercase",
    marginRight: "10px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
      marginRight: "5px",
    },
  },
  divider: {
    height: "20px",
    marginRight: "10px",
    [theme.breakpoints.down("xs")]: {
      marginRight: "5px",
    },
  },
  logo: {
    color: "black",
    textDecoration: "none",
    fontWeight: "500",
    fontSize: "16px",
    paddingLeft: "10px",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "5px",
    },
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.down("xs")]: {
      // zIndex: 5,
      // width: "0",
      display: "none",
    },
  },
  drawer1: {
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.down("xs")]: {},
  },

  icons: {
    width: "20px",
  },
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.down("xs")]: {
      // display: "none",
      zIndex: -1,
    },
  },
  menuBtn: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      marginLeft: "-6px",
      width: "25px",
    },
  },
  closeBtn: {
    display: "hidden",
    [theme.breakpoints.down("xs")]: {
      // backgroundColor: "white",
      width: "200px",
      minHeight: "400px",
      position: "absolute",
      top: "17px",
      zIndex: 10,
      left: "0",
      paddingLeft: "10px",
    },
  },
  drawerContainer: {
    overflow: "auto",
    fontWeight: "500",
    color: "#71717A",
    [theme.breakpoints.down("xs")]: {
      // display: "none",
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      marginTop: "-100px",
    },
  },
  middle: {
    display: "flex",
    justifyContent: "space-evenly",
    marginRight: "20px",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },

  search: {
    position: "relative",
    borderRadius: "20px",
    border: "1px solid rgb(211,211,211)",
    marginRight: "10px",
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    width: "24%",
    [theme.breakpoints.down("sm")]: {
      width: "55%",
      height: 30,
      marginRight: "0px",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    color: "rgb(211,211,211)",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 1),
    },
  },
  inputRoot: {
    color: "inherit",
    [theme.breakpoints.down("sm")]: {},
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "12ch",
      "&::placeholder": {
        fontSize: "0.8rem",
      },
      fontSize: "15px",
      padding: theme.spacing(0.5, 0.5, 1, 1),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(2)}px)`,
    },
  },
  announcement: {
    padding: "5px",
    border: "1px solid rgb(211,211,211)",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: "500",
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: "10px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
      marginLeft: "40px",
      marginRight: "40px",
    },
  },
  announceIcon: {
    width: "20px",
    [theme.breakpoints.down("sm")]: {
      width: "15px",
    },
  },
  ranking: {
    padding: "5px",
    border: "none",
    borderRadius: "5px",
    background: "#eb6e13",
    color: "#fff",
    fontSize: "14px",
    "&:hover": {
      backgroundColor: "#EE7D2A",
    },
    fontWeight: "500",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
      marginLeft: "40px",
      marginRight: "40px",
    },
  },
  avatar: {
    [theme.breakpoints.down("sm")]: {
      marginRight: "-20px",
    },
  },
  logout: {
    [theme.breakpoints.down("sm")]: {
      marginRight: "20px",
    },
  },
}));

export default function Sidebar() {
  const [click, setClicked] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [searchText, setText] = useContext(UserContext);
  const classes = useStyles();

  const history = useHistory();
  var user = auth.currentUser;
  var email;
  if (user != null) {
    email = user.email.replace("@siciliamia.com", "");
  }

  const handleLogout = () => {
    auth
      .signOut()
      .then((auth) => {
        history.push("/");
        window.location.reload();
      })
      .catch((error) => alert(error.message));
  };
  const side = (
    <Drawer
      className={classes.drawer1}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Toolbar />
      <List>
        <Link href="/entrypage" underline="none" color="inherit">
          <ListItem button>
            <ListItemIcon>
              <div className={classes.drawerContainer} />
              <img className={classes.icons} src={PostIcon} alt="" />
            </ListItemIcon>
            <p className={classes.link}>New Entry</p>
          </ListItem>
        </Link>

        <Link href="/draft" underline="none" color="inherit">
          <ListItem button>
            <ListItemIcon>
              <img className={classes.icons} src={DraftIcon} alt="" />
            </ListItemIcon>
            <p className={classes.link}>Draft</p>
          </ListItem>
        </Link>

        <Link href="/process" underline="none" color="inherit">
          <ListItem button>
            <ListItemIcon>
              <img className={classes.icons} src={ProcessIcon} alt="" />
            </ListItemIcon>
            <p className={classes.link}>Uploaded</p>
          </ListItem>
        </Link>

        {(user?.email?.trim() === SMIAUserTypes.SMIA_EDITOR_SUPERVISOR ||
          user?.email.trim() === SMIAUserTypes.SMIA_CONTENT_SUPERVISOR ||
          user?.email.trim() === SMIAUserTypes.SMIA_MARKETING_SUPERVISOR ||
          user?.email.trim() === SMIAUserTypes.SMIA_EDITOR_MANAGER ||
          user?.email.trim() === SMIAUserTypes.SMIA_CONTENT_MANAGER ||
          user?.email.trim() === SMIAUserTypes.SMIA_MARKETING_MANAGER ||
          user?.email.trim() === SMIAUserTypes.SMIA_IT_MANGER) && (
          <Link href="/rejected" underline="none" color="inherit">
            <ListItem button>
              <ListItemIcon>
                <img className={classes.icons} src={ApproveIcon} alt="" />
              </ListItemIcon>
              <p className={classes.link}>Accepted/Rejected</p>
            </ListItem>
          </Link>
        )}

        <Link href="/finished" underline="none" color="inherit">
          <ListItem button>
            <ListItemIcon>
              <img className={classes.icons} src={FinishIcon} alt="" />
            </ListItemIcon>
            <p className={classes.link}>Approved Articles</p>
          </ListItem>
        </Link>
      </List>
      <Divider variant="middle" style={{ marginTop: "10px" }} />
      <button
        onClick={() => history.push("/announcement")}
        className={classes.announcement}
      >
        <img className={classes.announceIcon} src={AnnounceIcon} alt="" />{" "}
        &nbsp; Announcements
      </button>

      <button
        onClick={() => history.push("/ranking")}
        className={classes.ranking}
      >
        + Ranking System
      </button>
    </Drawer>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />

      <AppBar position="fixed" className={classes.appBar} elevation={0}>
        <div className={classes.header}>
          <Toolbar>
            <Menu
              onClick={() => setClicked(true)}
              className={classes.menuBtn}
            />
            <Link href="/entrypage" color="inherit" underline="none">
              <p className={classes.logo}>Siciliamia</p>
            </Link>
          </Toolbar>

          <div className={classes.userBox}>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search articles..."
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
                onChange={(e) => setText(e.target.value)}
              />
            </div>
            <div className={classes.middle}>
              <button
                onClick={() => history.push("/announcement")}
                className={classes.announcement}
              >
                <img
                  className={classes.announceIcon}
                  src={AnnounceIcon}
                  alt=""
                />{" "}
                &nbsp; Announcements
              </button>

              <button
                onClick={() => history.push("/ranking")}
                className={classes.ranking}
              >
                + Ranking System
              </button>
            </div>

            <Toolbar>
              <Typography className={classes.email} noWrap>
                {email}
              </Typography>
              <Divider orientation="vertical" className={classes.divider} />
              <AccountCircleIcon className={classes.avatar} />
            </Toolbar>
            <Tooltip title="Log Out">
              <IconButton aria-label="" onClick={handleLogout}>
                <ExitToAppIcon className={classes.logout} />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </AppBar>

      {click && (
        <div className={classes.closeBtn}>
          {side}
          <Close onClick={() => setClicked(false)} />
        </div>
      )}
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />

        <List>
          <Link href="/entrypage" underline="none" color="inherit">
            <ListItem button>
              <ListItemIcon>
                <div className={classes.drawerContainer} />
                <img className={classes.icons} src={PostIcon} alt="" />
              </ListItemIcon>
              <p className={classes.link}>New Entry</p>
            </ListItem>
          </Link>

          <Link href="/draft" underline="none" color="inherit">
            <ListItem button>
              <ListItemIcon>
                <img className={classes.icons} src={DraftIcon} alt="" />
              </ListItemIcon>
              <p className={classes.link}>Draft</p>
            </ListItem>
          </Link>

          <Link href="/process" underline="none" color="inherit">
            <ListItem button>
              <ListItemIcon>
                <img className={classes.icons} src={ProcessIcon} alt="" />
              </ListItemIcon>
              <p className={classes.link}>Uploaded</p>
            </ListItem>
          </Link>

          {(user?.email?.trim() === SMIAUserTypes.SMIA_EDITOR_SUPERVISOR ||
            user?.email.trim() === SMIAUserTypes.SMIA_CONTENT_SUPERVISOR ||
            user?.email.trim() === SMIAUserTypes.SMIA_MARKETING_SUPERVISOR ||
            user?.email.trim() === SMIAUserTypes.SMIA_EDITOR_MANAGER ||
            user?.email.trim() === SMIAUserTypes.SMIA_CONTENT_MANAGER ||
            user?.email.trim() === SMIAUserTypes.SMIA_MARKETING_MANAGER ||
            user?.email.trim() === SMIAUserTypes.SMIA_IT_MANGER) && (
            <Link href="/rejected" underline="none" color="inherit">
              <ListItem button>
                <ListItemIcon>
                  <img className={classes.icons} src={ApproveIcon} alt="" />
                </ListItemIcon>
                <p className={classes.link}>Accepted/Rejected</p>
              </ListItem>
            </Link>
          )}

          <Link href="/finished" underline="none" color="inherit">
            <ListItem button>
              <ListItemIcon>
                <img className={classes.icons} src={FinishIcon} alt="" />
              </ListItemIcon>
              <p className={classes.link}>Approved Articles</p>
            </ListItem>
          </Link>
        </List>
        <Divider variant="middle" />
      </Drawer>
    </div>
  );
}
