import { Box, Divider, Grid, Typography } from "@material-ui/core";
import ScoreCard from "./ScoreCard";
import { Title } from "./ScoreCard";
import { makeStyles } from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";

const useStyles = makeStyles((theme) => ({
  scorePart: {
    marginRight: "1rem",
    width: "28vw",
    [theme.breakpoints.down("xs")]: {
      // marginRight: "1rem",
    },
  },
  Stars: {
    display: "flex",
    justifyContent: " flex-end",
    marginBottom: "5px",
  },
  titles: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "16px",
    letterSpacing: "0em",
    textAlign: "left",
    color: "#65676D",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
  Divider: {
    margin: "0.7rem 0",
    width: "28vw",
    [theme.breakpoints.down("xs")]: {
      width: "68vw",
    },
  },
  Divider1: {
    margin: "0.7rem 0",
    width: "28vw",
    [theme.breakpoints.down("xs")]: {
      width: "68vw",
    },
  },
  split: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-between",
      alignItems: "center",
      // height: "40px",
      // padding: " 0 40px",
    },
  },
  editors: {
    height: "5vh",
    width: "28vw",
    paddingTop: "1rem",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      width: "68vw",
    },
  },
  posting: {
    marginTop: "25px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "13vh",
    position: "relative",
  },
  line: {
    height: "15vh",
    width: "1px",
    backgroundColor: "#E4E4E7",
    position: "absolute",
  },
  gridMob: {
    margin: "0.8rem 0",
    height: "7.3vh",
    width: "28vw",
    [theme.breakpoints.down("xs")]: {
      width: "160vw",
      margin: "0.4rem 0",
    },
  },
}));
export default function YourScore() {
  const classes = useStyles();
  return (
    <>
      <ScoreCard xsSize={12} mdSize={6} card={true}>
        <Box
          className={classes.scorePart}
          display="flex"
          flexGrow="1"
          flexDirection="column"
        >
          <Title title="Department Score" subTitle="Sicilia Mia" />
          <Grid container className={classes.gridMob}>
            <Grid
              item
              xs={5}
              md={12}
              alignItems="left"
              className={classes.split}
            >
              <Typography style={{ color: "#000" }} className={classes.titles}>
                {" "}
                Weekly Ratings
              </Typography>
              <Typography>Week 01</Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.editors}>
            <Grid item xs={2}>
              <Typography className={classes.titles}> {"Editor"}</Typography>
            </Grid>
            <Grid item className={classes.Stars} xs={5} md={7}>
              <Rating
                size="small"
                name="read-only"
                value={4}
                readOnly
                max={4}
              />
            </Grid>
          </Grid>
          <Divider className={classes.Divider} />
          <Grid container className={classes.editors}>
            <Grid item xs={2}>
              <Typography className={classes.titles}> {"Language"}</Typography>
            </Grid>
            <Grid item className={classes.Stars} xs={5} md={7}>
              <Rating
                size="small"
                name="read-only"
                value={4}
                readOnly
                max={4}
              />
            </Grid>
          </Grid>
          <Divider className={classes.Divider} />
          <Grid container className={classes.editors}>
            <Grid item xs={2}>
              <Typography className={classes.titles}> {"Marketing"}</Typography>
            </Grid>
            <Grid item className={classes.Stars} xs={5} md={7}>
              <Rating
                size="small"
                name="read-only"
                value={4}
                readOnly
                max={4}
              />
            </Grid>
          </Grid>
          <Divider className={classes.Divider} />
          <Typography style={{ paddingTop: "1.5rem" }}>Change Week</Typography>
        </Box>
      </ScoreCard>

      <ScoreCard xsSize={12} mdSize={6} card={true}>
        <Box
          className={classes.scorePart}
          display="flex"
          flexGrow="1"
          flexDirection="column"
        >
          <Title title="Department Score" subTitle="Sicilia Mia" />
          <Grid container className={classes.gridMob}>
            <Grid
              item
              xs={5}
              md={12}
              alignItems="left"
              className={classes.split}
            >
              <Typography style={{ color: "#000" }} className={classes.titles}>
                Monthly Ratings
              </Typography>
              <Typography>August</Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.editors}>
            <Grid item xs={2}>
              <Typography className={classes.titles}> {"Editor"}</Typography>
            </Grid>
            <Grid item className={classes.Stars} xs={5} md={7}>
              <Rating
                size="small"
                name="read-only"
                value={4}
                readOnly
                max={4}
              />
            </Grid>
          </Grid>
          <Divider className={classes.Divider} />
          <Grid container className={classes.editors}>
            <Grid item xs={2}>
              <Typography className={classes.titles}> {"Language"}</Typography>
            </Grid>
            <Grid item className={classes.Stars} xs={5} md={7}>
              <Rating
                size="small"
                name="read-only"
                value={4}
                readOnly
                max={4}
              />
            </Grid>
          </Grid>
          <Divider className={classes.Divider} />
          <Grid container className={classes.editors}>
            <Grid item xs={2}>
              <Typography className={classes.titles}> {"Marketing"}</Typography>
            </Grid>
            <Grid item className={classes.Stars} xs={5} md={7}>
              <Rating
                size="small"
                name="read-only"
                value={4}
                readOnly
                max={4}
              />
            </Grid>
          </Grid>
          <Divider className={classes.Divider} />
          <Typography style={{ paddingTop: "1.5rem" }}>Change Month</Typography>
        </Box>
      </ScoreCard>

      <ScoreCard xsSize={12} mdSize={6} card={false}>
        <Box display="flex" flexGrow="1" flexDirection="column">
          <Title title="Posting Rate" subTitle="Sicilia Mia" />
          <Divider className={classes.Divider1} />
          <Grid container className={classes.posting}>
            <Grid item xs={5} md={7} alignItems="center">
              <Typography
                style={{
                  color: "#000",
                  backgroundColor: "#FFF2EC",
                  width: "90px",
                  borderRadius: "6px",
                  height: "30px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className={classes.titles}
              >
                {" "}
                Week 01
              </Typography>
            </Grid>
            <Grid item xs={5} md={3}>
              <Typography
                style={{ color: "#000", fontSize: "18px" }}
                className={classes.titles}
              >
                3.7 %
              </Typography>
            </Grid>
            <div className={classes.line} />
            <Grid item xs={5} md={7} alignItems="center">
              <Typography
                style={{
                  color: "#000",
                  backgroundColor: "#FFF2EC",
                  width: "90px",
                  borderRadius: "px",
                  height: "30px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className={classes.titles}
              >
                August
              </Typography>
            </Grid>
            <Grid item xs={5} md={3} alignItems="center">
              <Typography
                style={{ color: "#000", fontSize: "18px" }}
                className={classes.titles}
              >
                3.7 %
              </Typography>
            </Grid>
          </Grid>
          <Typography style={{ paddingTop: "1.5rem" }}>Change Date</Typography>
        </Box>
      </ScoreCard>
      <ScoreCard xsSize={12} mdSize={6} card={true}>
        <Box
          className={classes.scorePart}
          display="flex"
          flexGrow="1"
          flexDirection="column"
        >
          <Title title="Overall" subTitle="Sicilia Mia" />
          <Grid
            container
            className={classes.editors}
            style={{ marginTop: "3rem" }}
          >
            <Grid item xs={2}>
              <Typography className={classes.titles}>{"Combined"}</Typography>
            </Grid>
            <Grid item className={classes.Stars} xs={5} md={7}>
              <Rating
                size="small"
                name="read-only"
                value={4}
                readOnly
                max={4}
              />
            </Grid>
          </Grid>
          <Divider className={classes.Divider} />
          <Grid container className={classes.editors}>
            <Grid item xs={2}>
              <Typography className={classes.titles}> {"Editor"}</Typography>
            </Grid>
            <Grid item className={classes.Stars} xs={5} md={7}>
              <Rating
                size="small"
                name="read-only"
                value={4}
                readOnly
                max={4}
              />
            </Grid>
          </Grid>
          <Divider className={classes.Divider} />
          <Grid container className={classes.editors}>
            <Grid item xs={2}>
              <Typography className={classes.titles}> {"Language"}</Typography>
            </Grid>
            <Grid item className={classes.Stars} xs={5} md={7}>
              <Rating
                size="small"
                name="read-only"
                value={4}
                readOnly
                max={4}
              />
            </Grid>
          </Grid>
          <Divider className={classes.Divider} />
          <Grid container className={classes.editors}>
            <Grid item xs={2}>
              <Typography className={classes.titles}> {"Marketing"}</Typography>
            </Grid>
            <Grid item className={classes.Stars} xs={5} md={7}>
              <Rating
                size="small"
                name="read-only"
                value={4}
                readOnly
                max={4}
              />
            </Grid>
          </Grid>
          <Divider className={classes.Divider} />
        </Box>
      </ScoreCard>
    </>
  );
}
