import React, { useState, createContext } from "react";

export const PostDetailsContext = createContext({});
PostDetailsContext.displayName = "postdetails";

const PostDetails = ({ children }) => {
  const [postInfo, setPostInfo] = useState({});

  return (
    <PostDetailsContext.Provider value={{ postInfo, setPostInfo }}>
      {children}
    </PostDetailsContext.Provider>
  );
};

export default PostDetails;
