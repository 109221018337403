/* eslint-disable no-unused-vars */
import React, { useState, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import Region from "./../components/create_post/Region";
import Category from "./../components/create_post/Category";
import PostAttributes from "./create_post/PostAttributes";
import { PostDetailsContext } from "../contexts/PostDetails";
import { MenuProps } from "../constants";
import { updateArticle, saveAsArticle } from "../services/ArticleService";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  dialog: {
    minWidth: 600,
  },
}));
const EditPost = (props) => {
  const { id, open, handleClose } = props;
  const { postInfo, setPostInfo } = useContext(PostDetailsContext);
  const [notifyMessage, setNotifyMessage] = useState({ notify: false });
  // const [uploadedMediaList, setUploadedMediaList] = useState({
  //   success: false,
  // });
  const classes = useStyles();

  const updatePost = (evt) => {
    evt.preventDefault();
    // console.log(postInfo);
    updateArticle(postInfo, id)
      .then((res) => {
        handleClose();
      })
      .catch((error) => {
        console.log(`Error ${error}`);
        handleClose();
      });
  };

  const saveDraftToArticle = (evt) => {
    evt.preventDefault();
    saveAsArticle(id)
      .then((res) => {
        handleClose();
      })
      .catch((error) => {
        handleClose();
      });
  };

  return (
    <div className={classes.body}>
      <Dialog className={classes.dialog} open={open} onClose={handleClose}>
        <DialogTitle> Edit post</DialogTitle>
        <DialogContent>
          <Region classes={classes} />
          <Category classes={classes} />
          <PostAttributes
            MenuProps={MenuProps}
            classes={classes}
            isEdit={true}
            notifyMessage={notifyMessage}
            // handleImages={handleImages}
          />
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>

            <Button onClick={(evt) => updatePost(evt)} color="primary">
              {postInfo.isdraft ? "Save as Draft" : "Update"}
            </Button>
            {postInfo.isdraft && (
              <Button
                onClick={(evt) => saveDraftToArticle(evt)}
                color="primary"
              >
                Save as Article
              </Button>
            )}
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditPost;
