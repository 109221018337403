import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import YourScore from "./YourScore";

const useStyles = makeStyles((theme) => ({
  score: {
    width: "75vw",
    margin: "0 auto",
    [theme.breakpoints.down("xs")]: {
      width: "97%",
      marginTop: "4rem",
    },
  },
}));

export default function Score() {
  const classes = useStyles();
  return (
    <Grid className={classes.score} container spacing={3}>
      <YourScore />
    </Grid>
  );
}
