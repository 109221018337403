import React from "react";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import WbIncandescentOutlinedIcon from "@material-ui/icons/WbIncandescentOutlined";
import ApprIcon from "../../assets/images/icons/approved.png";
import rejecIcon from "../../assets/images/icons/rejected.png";
import ArticleView from "./ArticleView";
import { cardTypes } from "../../constants";
import { acceptRejBy } from "../../helper/postStatus";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    display: "flex",
    alignItems: "flex-start",
    // justifyContent: "center",
    maxWidth: "260px",
    // maxHeight: "380px",
    minHeight: "15vh",
    // height: "20vh",
    overflow: "auto",
    margin: "10px",
    padding: "10px",
    flexFlow: "collumn-wrap",
    textAlign: "flex-start",
    border: "whitesmoke solid 2px",
    borderRadius: "15px",
    webkitBoxShadow: "1px 2px 4px 6px #ccc",
    mozBoxShadow: "3px 3px 5px 6px #ccc",
    boxShadow: "1px 2px 8px 2px #ccc",
    [theme.breakpoints.down("sm")]: {
      margin: "10px",
      padding: "10px",
      maxWidth: "100%",
      width: "100%",
      height: "170px",
      justifyContent: "space-between",
    },
  },

  cardTitle: {
    fontSize: "18px",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  cardUploadDate: {
    textTransform: "capitalize",
    fontStyle: "italic",
    opacity: ".7",
  },
  cardAcceptedByContainer: {
    width: "10em",
    background: "#68c454",
    opacity: ".5",
    borderRadius: "15px",
  },
  cardAcceptedBy: {
    color: "#398129",
    backgroundColor: "#a4d19a",
    border: "1px #a4d19a solid",
    borderRadius: "7px",
    paddingLeft: "10px",
    fontWeight: "bold",
    padding: "10",
    width: "auto",
    margin: "10px",
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      margin: "5px",
      padding: "5",
    },
  },
  cardRejectedBy: {
    color: "#f44336",
    backgroundColor: "#f2c7c4",
    border: "1px #f2c7c4 solid",
    borderRadius: "7px",
    paddingLeft: "10px",
    fontWeight: "bold",
    padding: "10",
    width: "auto",
    margin: "10px",
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      margin: "5px",
      padding: "5",
    },
  },
  inprocess: {
    color: "#ff9100",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    marginTop: "10px",
    marginBottom: "10px",
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      marginTop: "5px",
      marginBottom: "5px",
    },
  },

  cardFinalRowContainer: {
    display: "flex",
    justifyContent: "center",
  },
  cardViewButton: {
    border: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
    color: "rgb(6,69,173)",
    position: "relative",
    bottom: "0px",
  },

  icon: {
    width: "10%",
    [theme.breakpoints.down("sm")]: {
      width: "25px",
      height: "25px",
    },
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    overflow: "auto",
  },
}));

export default function PostCard({ id, typeOfCard, postDetails }) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const accptRejInfo = acceptRejBy(
    postDetails?.postStatus,
    postDetails?.username
  );
  const date = new Date(postDetails?.createdAt?.seconds * 1000);
  const displayDate =
    date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();

  return (
    <div className={classes.cardContainer}>
      <div className={classes.cardStyle}>
        <h1 className={classes.cardTitle}> {postDetails.attraction}</h1>
        <p className={classes.cardUploadDate}>uploaded on: {displayDate}</p>
        {typeOfCard === cardTypes.FINISHED && (
          <p className={classes.cardAcceptedBy}>Finished</p>
        )}

        {typeOfCard === cardTypes.IN_PROCESS && (
          <div>
            {postDetails.attraction}
            {accptRejInfo?.accLst?.length > 0 ? (
              <div>
                <p className={classes.inprocess}>
                  <WbIncandescentOutlinedIcon className={classes.icon} />{" "}
                  Status:
                </p>
                <ol className={classes.cardAcceptedList}>
                  {accptRejInfo?.accLst?.length > 0 &&
                    accptRejInfo?.accLst.map((ele, id) => (
                      <li key={id}> {ele != null && ele} </li>
                    ))}
                </ol>
              </div>
            ) : (
              <div>
                <p className={classes.inprocess}>
                  <WbIncandescentOutlinedIcon className={classes.icon} />{" "}
                  created
                </p>
              </div>
            )}
            {accptRejInfo?.rejBy?.trim().length > 0 && (
              <div>
                <p className={classes.cardRejectedBy}>rejected by:</p>
                <ol className={classes.cardAcceptedList}>
                  {
                    <li>
                      {accptRejInfo?.rejBy?.trim().length > 0 &&
                        accptRejInfo?.rejBy}
                    </li>
                  }
                </ol>
              </div>
            )}
          </div>
        )}
        {typeOfCard === cardTypes.APPROVED_OR_REJECTED && (
          <div>
            {accptRejInfo?.accLst?.length > 0 && (
              <div>
                <p className={classes.cardAcceptedBy}>accepted by:</p>
                <ol className={classes.cardAcceptedList}>
                  {accptRejInfo?.accLst?.length > 0 &&
                    accptRejInfo?.accLst.map((ele, id) => (
                      <li key={id}> {ele != null && ele} </li>
                    ))}
                </ol>
              </div>
            )}

            {accptRejInfo?.rejBy?.trim().length > 0 && (
              <div>
                <p className={classes.cardRejectedBy}>rejected by:</p>
                <ol className={classes.cardAcceptedList}>
                  {
                    <li>
                      {accptRejInfo?.rejBy?.trim().length > 0 &&
                        accptRejInfo?.rejBy}
                    </li>
                  }
                </ol>
              </div>
            )}
          </div>
        )}
        <div className={classes.cardFinalRowContainer}>
          <button
            type="button"
            className={classes.cardViewButton}
            onClick={handleOpen}
          >
            View the article and comments
          </button>
         

          {accptRejInfo?.rejBy?.trim().length > 0 ? (
            <img className={classes.icon} src={rejecIcon} alt="" />
          ) : (
            <img className={classes.icon} src={ApprIcon} alt="" />
          )}
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modal}
      >
        <ArticleView
          typeOfCard={typeOfCard}
          id={id}
          postDetails={postDetails}
          date={displayDate}
          onClose={handleClose}
        />
      </Modal>
    </div>
  );
}
