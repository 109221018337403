import React, { useState, useEffect, useContext } from "react";
import PostCard from "./PostCard";
import { makeStyles } from "@material-ui/core/styles";
import {
  cardTypes,
  SMIAUserTypes,
  ARTICLES_COLLECTION_NAME,
  postStatusTypes,
} from "../../constants";
import { db } from "../../firebase";
import { useAuth } from "../../contexts/user";
import { GridList, ImageList } from "@material-ui/core";
import { getPostStatus } from "../../helper/postStatus";
import { UserContext } from "../../App";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    marginTop: "75px",
    display: "flex",
    justifyContent: "flex-start",
    marginLeft: "18%",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-between",
      marginTop: "80px",
      marginLeft: "5px",
    },
  },
  postList: {
    display: "flex",
  },
}));

/**
 *
 * @param {*} param0
 * @returns
 */
export default function FetchPosts({ typeofCard }) {
  // eslint-disable-next-line no-unused-vars
  const [searchText, setText] = useContext(UserContext);
  const { email } = useAuth();
  const classes = useStyles();
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
    switch (typeofCard) {
      //  ------ FINISHED ------
      case cardTypes.FINISHED:
        if (
          email === SMIAUserTypes.SMIA_EDITOR_SUPERVISOR
        ) {
          db.collection(ARTICLES_COLLECTION_NAME)
            .where("isdraft", "==", false)
            .where("postStatus", "in", [
              postStatusTypes.EDITOR_SUPERVISOR_APPROVED
            ])
      
            .onSnapshot((snapshot) => {
              setPosts(
                snapshot.docs.map((doc) => ({ id: doc.id, item: doc.data() }))
              );
              setLoading(true);
            });
        } 

        if (
          email === SMIAUserTypes.SMIA_CONTENT_SUPERVISOR
        ) {
          db.collection(ARTICLES_COLLECTION_NAME)
            .where("isdraft", "==", false)
            .where("postStatus", "in", [
              postStatusTypes.CONTENT_SUPERVISOR_APPROVED,
            ])
      
            .onSnapshot((snapshot) => {
              setPosts(
                snapshot.docs.map((doc) => ({ id: doc.id, item: doc.data() }))
              );
              setLoading(true);
            });
        } 
        if (       
          email === SMIAUserTypes.SMIA_MARKETING_SUPERVISOR 
        ) {
          db.collection(ARTICLES_COLLECTION_NAME)
            .where("isdraft", "==", false)
            .where("postStatus", "in", [
              postStatusTypes.MARKETING_SUPERVISOR_APPROVED
            ])
      
            .onSnapshot((snapshot) => {
              setPosts(
                snapshot.docs.map((doc) => ({ id: doc.id, item: doc.data() }))
              );
              setLoading(true);
            });
        } 
        
        if (
          email === SMIAUserTypes.SMIA_IT_MANGER ||
          email === SMIAUserTypes.SMIA_OFFICE
        ) {
          db.collection(ARTICLES_COLLECTION_NAME)
            .where("isdraft", "==", false)
            .where("postStatus", "in", [
              postStatusTypes.EDITOR_SUPERVISOR_APPROVED,
              postStatusTypes.CONTENT_SUPERVISOR_APPROVED,
              postStatusTypes.MARKETING_SUPERVISOR_APPROVED
            ])
      
            .onSnapshot((snapshot) => {
              setPosts(
                snapshot.docs.map((doc) => ({ id: doc.id, item: doc.data() }))
              );
              setLoading(true);
            });
        } 

        else {
          db.collection(ARTICLES_COLLECTION_NAME)
            .where("username", "==", email)
            .where("isdraft", "==", false)
            .where("postStatus", "in", [
              postStatusTypes.EDITOR_SUPERVISOR_APPROVED,
              postStatusTypes.CONTENT_SUPERVISOR_APPROVED,
              postStatusTypes.MARKETING_SUPERVISOR_APPROVED
            ])
           
            .onSnapshot((snapshot) => {
              setPosts(
                snapshot.docs.map((doc) => ({ id: doc.id, item: doc.data() }))
              );
              setLoading(true);
            });
        }
        break;
      //  ------ APPROVED or REJECTED  ------
      case cardTypes.APPROVED_OR_REJECTED:
        let postStatus = getPostStatus(email);
        db.collection(ARTICLES_COLLECTION_NAME)
          .where("isdraft", "==", false)
          .where("postStatus", "in", [
            postStatusTypes.EDITOR_SUPERVISOR_REJECTED,
            postStatusTypes.CONTENT_SUPERVISOR_REJECTED,
            postStatusTypes.MARKETING_SUPERVISOR_REJECTED,
            postStatusTypes.EDITOR_SUPERVISOR_APPROVED,
            postStatusTypes.CONTENT_SUPERVISOR_APPROVED,
            postStatusTypes.MARKETING_SUPERVISOR_APPROVED,
            /*
            postStatusTypes.EDITOR_MANAGER_REJECTED,
            postStatusTypes.CONTENT_MANGER_REJECTED,
            postStatusTypes.MARKETING_MANAGER_REJECTED,
            */
            postStatusTypes.ADMIN_REJECTED,
            postStatus,
          ])
          .onSnapshot((snapshot) => {
            setPosts(
              snapshot.docs.map((doc) => ({ id: doc.id, item: doc.data() }))
            );
            setLoading(true);
          });
        break;

      //  ------ IN PROCESS ------
      case cardTypes.IN_PROCESS:
        if (
          email === SMIAUserTypes.SMIA_EDITOR_SUPERVISOR ||
          email === SMIAUserTypes.SMIA_CONTENT_SUPERVISOR ||
          email === SMIAUserTypes.SMIA_MARKETING_SUPERVISOR ||
          /*
          email === SMIAUserTypes.SMIA_EDITOR_MANAGER ||
          email === SMIAUserTypes.SMIA_CONTENT_MANAGER ||
          email === SMIAUserTypes.SMIA_MARKETING_MANAGER ||
          */
          email === SMIAUserTypes.SMIA_OFFICE ||
          email === SMIAUserTypes.SMIA_IT_MANGER
        ) {
          db.collection(ARTICLES_COLLECTION_NAME)
            .where("isdraft", "==", false)
            .onSnapshot((snapshot) => {
              setPosts(
                snapshot.docs.map((doc) => ({ id: doc.id, item: doc.data() }))
              );
              setLoading(true);
            });
        } else {
          db.collection(ARTICLES_COLLECTION_NAME)
            .where("username", "==", email)
            .where("isdraft", "==", false)
            .onSnapshot((snapshot) => {
              setPosts(
                snapshot.docs.map((doc) => ({ id: doc.id, item: doc.data() }))
              );
              setLoading(true);
            });
        }
        break;

      // ---- DRAFTS --------
      case cardTypes.DRAFTS:
        db.collection(ARTICLES_COLLECTION_NAME)
          .where("username", "==", email)
          .where("isdraft", "==", true)
          .onSnapshot((snapshot) => {
            setPosts(
              snapshot.docs.map((doc) => {
                return { id: doc.id, item: doc.data() };
              })
            );
            setLoading(true);
          });
        break;

      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  console.log(posts);
  return (
    <div className={classes.cardContainer}>
      {loading && (
        <ImageList rowHeight={330} className={classes.gridList} cols={4}>
          {posts.map(({ id, item }) => (
            <div className={classes.postList} key={id}>
              {searchText === "" ? (
                <PostCard
                  key={id}
                  typeOfCard={typeofCard}
                  id={id}
                  postDetails={item}
                />
              ) : (
                <>
                  {item.attraction
                    .toLowerCase()
                    .includes(searchText.toLowerCase()) && (
                    <PostCard
                      key={id}
                      typeOfCard={typeofCard}
                      id={id}
                      postDetails={item}
                    />
                  )}
                </>
              )}
            </div>
          ))}
        </ImageList>
      )}
    </div>
  );
}
